import React, { useState, useEffect, useContext } from 'react';
import { AuthContext } from "../../provider/AuthProvider";
import { Download, MessageCircle } from 'lucide-react';
import { serverbaseURL } from "../../constant/index";

export default function BuyerDashboard() {
  const [purchases, setPurchases] = useState([]);
  const [invoices, setInvoices] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedPurchase, setSelectedPurchase] = useState(null);
  const { user } = useContext(AuthContext);
  const userId = user.uid;



  // Fetch purchase history
  useEffect(() => {
    const fetchPurchaseHistory = async () => {
      try {
        const response = await fetch(`${serverbaseURL}purchase-history/${userId}`);
        const data = await response.json();
        if (response.ok) {
          // Format the date for each purchase in the purchase history
          const formattedPurchases = data.purchaseHistory.map((purchase) => ({
            ...purchase,
            purchaseDate: formatDateTime(purchase.purchaseDate), // Format the date
          }));
          setPurchases(formattedPurchases);
        } else {
          console.error(data.message);
        }
      } catch (error) {
        console.error('Error fetching purchase history:', error);
      }
    };

    fetchPurchaseHistory();
  }, []);



  // Fetch invoices for each purchase
  useEffect(() => {
    const fetchInvoices = async () => {
      try {
        const invoicesData = await Promise.all(
          purchases.map(async (purchase) => {
            const response = await fetch(`${serverbaseURL}invoice/${purchase.purchaseId}`);
            const data = await response.json();
            if (response.ok) {
              const formattedDate = formatDateTime(data.invoice.date);
              return {
                id: data.invoice.invoiceId,
                receipt: data.invoice.receipt,
                amount: data.invoice.amount,
                date: formattedDate,
                status: data.invoice.status,
              };
            } else {
              console.error(data.message);
              return null;
            }
          })
        );
        setInvoices(invoicesData.filter((invoice) => invoice !== null));
      } catch (error) {
        console.error('Error fetching invoices:', error);
      }
    };

    if (purchases.length > 0) {
      fetchInvoices();
    }
  }, [purchases]);


  // Function to format the date in a standard format
  const formatDateTime = (isoDateString) => {
    const date = new Date(isoDateString);
    const day = String(date.getDate()).padStart(2, '0'); // Get day and pad if needed
    const month = String(date.getMonth() + 1).padStart(2, '0'); // Get month and pad if needed
    const year = date.getFullYear(); // Get full year
    const hours = date.getHours() % 12 || 12; // Convert to 12-hour format
    const minutes = String(date.getMinutes()).padStart(2, '0'); // Get minutes and pad if needed
    const ampm = date.getHours() >= 12 ? 'PM' : 'AM'; // Determine AM/PM

    return `${day}-${month}-${year} ${hours}:${minutes} ${ampm}`; // Return formatted date
  };




  const handleDownload = async (purchase) => {
    try {
      const purchaseId = purchase.purchaseId;

      // Update the local purchase state immediately to show incremented count (optimistic UI update)
      setPurchases((prevPurchases) =>
        prevPurchases.map((p) =>
          p.purchaseId === purchaseId
            ? { ...p, downloads: p.downloads + 1 }
            : p
        )
      );

      // Send a PUT request to increment the download count on the server
      const response = await fetch(`${serverbaseURL}update-downloads/${purchaseId}`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
        },
      });

      // Check if the response status is OK
      if (!response.ok) {
        const errorData = await response.json();
        console.error('Failed to update download count:', errorData.message || 'Unknown error');
        return;
      }

      const data = await response.json();

      // Update the local purchase state with the new download count from the server (if returned)
      setPurchases((prevPurchases) =>
        prevPurchases.map((p) =>
          p.purchaseId === purchaseId
            ? { ...p, downloads: data.updatedDownloads } // Update with the actual value from the server
            : p
        )
      );

      // Trigger the download if the download URL is provided
      if (data.imageUrl) {
        const a = document.createElement('a');
        a.href = data.imageUrl;
        a.download = ''; // Optional: You can give a custom filename here
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
      } else {
        console.error('Download URL not provided in response.');
      }
    } catch (error) {
      // Log network or other unexpected errors
      console.error('Error downloading template:', error.message || error);
    }
  };



  const handleViewDetails = (purchase) => {
    setSelectedPurchase(purchase);
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setSelectedPurchase(null);
  };

  return (
    <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-8">
      <h1 className="text-2xl font-bold text-gray-900 mb-8">My Purchases</h1>
      <div className="bg-white rounded-lg shadow-sm mb-8">
        <div className="overflow-x-auto">
          <table className="min-w-full divide-y divide-gray-200">
            <thead>
              <tr>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Template</th>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Purchase Date</th>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Price</th>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Downloads</th>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Seller</th>
                <th className="px-6 py-3 text-right text-xs font-medium text-gray-500 uppercase tracking-wider">Actions</th>
              </tr>
            </thead>
            <tbody className="divide-y divide-gray-200">
              {purchases.map((purchase) => (
                <tr key={purchase.purchaseId}>
                  <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                    {purchase.templateTitle}
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                    {purchase.purchaseDate}
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                    ₹{purchase.price}
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                    {purchase.downloads}
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                    {purchase.seller}
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap text-right text-sm font-medium">
                    <button
                      onClick={() => handleDownload(purchase)}
                      className="text-indigo-600 hover:text-indigo-900 mr-4"
                    >
                      <Download className="h-5 w-5" />
                    </button>

                    <button onClick={() => handleViewDetails(purchase)} className="text-indigo-600 hover:text-indigo-900 mr-4">
                      Details
                    </button>
                    {/* <button onClick={() => handleSupportRequest(purchase.templateId)} className="text-indigo-600 hover:text-indigo-900">
                      <MessageCircle className="h-5 w-5" />
                    </button> */}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>

      <div className="bg-white rounded-lg shadow-sm mb-8 p-6">
        <h2 className="text-xl font-bold text-gray-900 mb-4">Invoices</h2>
        <table className="min-w-full divide-y divide-gray-200">
          <thead>
            <tr>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Invoice ID</th>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Receipt No.</th>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Amount</th>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Date</th>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Status</th>
            </tr>
          </thead>
          <tbody className="divide-y divide-gray-200">
            {invoices.map((invoice) => (
              <tr key={invoice.id}>
                <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">{invoice.id}</td>
                <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">{invoice.receipt}</td>
                <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">₹{invoice.amount}</td>
                <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">{invoice.date}</td>
                <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">{invoice.status}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      {isModalOpen && selectedPurchase && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
          <div className="bg-white rounded-xl shadow-lg p-8 max-w-md w-full relative">
            <button
              onClick={closeModal}
              className="absolute top-4 right-4 text-gray-400 hover:text-gray-600 text-xl"
              aria-label="Close"
            >
              &times;
            </button>
            <h2 className="text-2xl font-bold text-gray-800 mb-4">{selectedPurchase.templateTitle}</h2>

            <img
              src={selectedPurchase.templateThumbnail}
              alt={selectedPurchase.templateTitle}
              className="w-full h-40 object-cover rounded-lg mb-4"
            />

            <div className="border-t border-gray-200 mt-2 pt-4">
              <p className="text-gray-600 text-sm mb-1"><strong>Price:</strong> <span className="text-gray-800 font-medium">₹{selectedPurchase.price}</span></p>
              <p className="text-gray-600 text-sm mb-1"><strong>Seller:</strong> <span className="text-gray-800 font-medium">{selectedPurchase.seller}</span></p>
              <p className="text-gray-600 text-sm mb-1"><strong>Purchase Date:</strong> <span className="text-gray-800 font-medium">{selectedPurchase.purchaseDate}</span></p>
              <p className="text-gray-600 text-sm mb-1"><strong>Payment Status:</strong> <span className="text-green-600 font-medium">{selectedPurchase.paymentStatus}</span></p>
              <p className="text-gray-600 text-sm mb-1"><strong>Payment Method:</strong> <span className="text-gray-800 font-medium">{selectedPurchase.paymentMethod}</span></p>
              <p className="text-gray-600 text-sm mb-1"><strong>Payment ID:</strong> <span className="text-gray-800 font-medium">{selectedPurchase.paymentId}</span></p>
              <p className="text-gray-600 text-sm mb-1"><strong>Seller Email:</strong> <span className="text-gray-800 font-medium">{selectedPurchase.useremail || "Not provided"}</span></p>
            </div>

            <div className="flex justify-end mt-6">
              <button
                onClick={closeModal}
                className="px-4 py-2 bg-red-500 text-white font-semibold rounded-md shadow hover:bg-red-600 transition duration-150 ease-in-out"
              >
                Close
              </button>
            </div>
          </div>
        </div>
      )}


    </div>
  );
}
