import axios from "axios";
import GradientHeading from "../components/GradientHeading";
import { useContext, useState } from "react";
import { serverbaseURL } from "../constant/index";
import { AuthContext } from "../provider/AuthProvider";
import correct from "../assets/icons8-correct-50.png";
import wrong from "../assets/icons8-wrong-50.png";
import { motion } from 'framer-motion'; // Importing Framer Motion
import { FaCheck, FaTimes } from 'react-icons/fa'; // Importing React Icons (check and times)
import { div } from "framer-motion/client";

const Billing = () => {
  const { user } = useContext(AuthContext);
  const [responseId, setResponseId] = useState("");
  const [responseState, setResponseState] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const loadScript = (src) => {
    return new Promise((resolve) => {
      const script = document.createElement("script");

      script.src = src;

      script.onload = () => {
        resolve(true);
      };
      script.onerror = () => {
        resolve(false);
      };

      document.body.appendChild(script);
    });
  };

  const handlePayment = async (e, price) => {
    e.preventDefault();
    // if(!userPlan){
    //   alert("Please refresh the window and try again.")
    //   return
    // }
    const amount = price * 100;
    const currency = "INR";
    const receipt = `receipt_${Math.random().toString(36).substring(7)}`;

    const paymentData = {
      userId: user,
      amount,
      currency,
      receipt,
    };

    let config = {
      method: "post",
      maxBodyLength: Infinity,
      url: `${serverbaseURL}order`,
      headers: {
        "Content-Type": "application/json",
      },
      data: paymentData,
    };

    axios
      .request(config)
      .then((response) => {
        handleRazorpayScreen(response.data.amount);
      })
      .catch((error) => {
        console.error("error at", error);
      });
  };

  const handleRazorpayScreen = async (amount) => {
    const res = await loadScript("https:/checkout.razorpay.com/v1/checkout.js");

    if (!res) {
      alert("Some error at razorpay screen loading");
      return;
    }

    const options = {
      key: "rzp_test_GcZZFDPP0jHtC4",
      amount: amount,
      currency: "INR",
      name: "AutoMovieCreator",
      description: "Payment to AutoMovieCreator",
      image: "https://papayacoders.com/demo.png",
      handler: async function (response) {
        setResponseId(response.razorpay_payment_id);
        // Make the second API call after Razorpay payment is completed
        try {
          const paymentResponse = await axios.get(
            `${serverbaseURL}payment/${response.razorpay_payment_id}`
          );
          setResponseState(paymentResponse.data);
          setIsModalOpen(true);
          console.log('payment response', paymentResponse.data);
          // Make the new API call to payment-confirmation
          await axios.post(`${serverbaseURL}payment-confirmation`, {
            email: user?.email,
            amount: amount / 100,
            paymentId: paymentResponse.data.paymentId,
            method: paymentResponse.data.method,
            success: true,
          });
        } catch (error) {
          // Make the new API call to payment-confirmation in case of error
          await axios.post(`${serverbaseURL}payment-confirmation`, {
            email: user.email,
            amount: amount / 100,
            success: false,
          });
          console.error("Error occurred while fetching payment details", error);
        }
      },
      prefill: {
        name: "Auto Movie Creator",
        email: "tech@automoviecreator.com",
      },
      theme: {
        color: "#F4C430",
      },
    };

    const paymentObject = new window.Razorpay(options);
    paymentObject.open();
  };

  // Modal component
  const PaymentModal = () => (
    <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
      <div className="bg-white p-8 rounded-lg shadow-lg max-w-lg w-full">
        <h2 className="text-2xl font-bold mb-4">Payment Details</h2>
        {responseState.length !== 0 && (
          <ul>
            <li>Amount: {responseState.amount / 100} Rs.</li>
            <li>Currency: {responseState.currency}</li>
            <li>Status: {responseState.status}</li>
            <li>Method: {responseState.method}</li>
          </ul>
        )}
        <button
          className="mt-4 px-4 py-2 bg-blue-500 text-white rounded-lg"
          onClick={() => setIsModalOpen(false)}
        >
          Close
        </button>
      </div>
    </div>
  );

  return (
    <div className=" min-h-screen">
      <div className="max-w-6xl mx-auto">
        <div className="pt-20 px-5 ml-5 mb-2 pb-5">
          <GradientHeading text="CURRENT PLAN" />
        </div>
        <div className="px-5">
          <div className="px-5">
            <motion.div className="bg-[#805af5] max-w-2xl px-8 py-10 md:px-16 shadow-xl rounded-lg"
              initial={{ opacity: 0, x: -1000, scale: 0.95 }} // Starts off-screen to the left with slight scale down
              animate={{ opacity: 1, x: 0, scale: 1 }} // Animates to full opacity and position with scale up
              transition={{
                duration: 0.4, // Duration for smooth transition
                ease: 'easeInOut', // Smooth easing function for smooth and natural animation
                delay: 0.1, // A slight delay for a more fluid effect
              }}
            >
              <p className="text-lg text-white">
                <span className="font-bold">Current Plan:</span> Free
              </p>
              <p className="text-lg text-white">
                <span className="font-bold">Max Series:</span>   1
              </p>
              <p className="text-lg text-white">
                <span className="font-bold">Frequency:</span>   1 Video Creation
              </p>
            </motion.div>
          </div>

        </div>
        <div className="pt-14 px-5 mt-2 ml-6 ">
          <GradientHeading text="CHANGE PLAN" />
        </div>
        {/* card container */}

        <div className=" p-5 rounded-lg grid grid-cols-1 md:grid-cols-2 xl:grid-cols-2 gap-5 ">
          {/* card 1 */}



          <div className="h-full relative inline-block p-4  text-white">
            <div className="relative z-10 border-2 border-[#805af5] rounded-xl bg-[#f8f8ff] p-8 h-full shadow-lg hover:shadow-2xl hover:border-[#0d6efd]   ">
              <div ></div>


              {/* FREE Section */}
              <p className="text-black text-[48px] leading-tight block text-center bg-opacity-100 pb-1 font-serif">FREE</p>
              <h1 className="text-gray-700 mb-5 text-[44px] leading-tight block text-center bg-opacity-100 pb-2 ">
                ₹0
              </h1>

              {/* Features Heading */}
              <h2 className="text-black text-left pb-2 opacity-100  text-[17px]  font-[500] font-serif mb-4">Features</h2>

              {/* Features List */}
              <div className="">
                <div className="flex justify-start items-center gap-2">
                  <FaCheck className="h-4 w-4 text-green-400" />
                  <p className="text-[#55595c]    text-[16px] leading-[1.67] font-[500] bg-opacity-100">
                    Create 1 Video
                  </p>
                </div>
                <div className="flex justify-start items-center gap-2">
                  <FaCheck className="h-4 w-4 text-green-400" />
                  <p className="text-[#55595c]    text-[16px] leading-[1.67] font-[500] bg-opacity-100">
                    1 Series
                  </p>
                </div>
                <div className="flex justify-start items-center gap-2">
                  <FaCheck className="h-4 w-4 text-green-400" />
                  <p className="text-[#55595c]    text-[16px] leading-[1.67] font-[500] bg-opacity-100">
                    Edit & Preview Videos
                  </p>
                </div>

                {/* Disabled Features */}
                <div className="flex justify-start items-center gap-2">
                  <FaTimes className="h-4 w-4 text-red-400" />
                  <p className="line-through text-[#55595c]    text-[16px] leading-[1.67] font-[500] bg-opacity-100">Auto Post To Channel</p>
                </div>
                <div className="flex justify-start items-center gap-2">
                  <FaTimes className="h-4 w-4 text-red-400" />
                  <p className="line-through text-[#55595c]    text-[16px] leading-[1.67] font-[500] bg-opacity-100">HD Video Resolution</p>
                </div>
                <div className="flex justify-start items-center gap-2">
                  <FaTimes className="h-4 w-4 text-red-400" />
                  <p className="line-through text-[#55595c]    text-[16px] leading-[1.67] font-[500] bg-opacity-100">Background Music</p>
                </div>
                <div className="flex justify-start items-center gap-2">
                  <FaTimes className="h-4 w-4 text-red-400" />
                  <p className="line-through text-[#55595c]    text-[16px] leading-[1.67] font-[500] bg-opacity-100">No Watermark</p>
                </div>
                <div className="flex justify-start items-center gap-2">
                  <FaTimes className="h-4 w-4 text-red-400" />
                  <p className="line-through text-[#55595c]    text-[16px] leading-[1.67] font-[500] bg-opacity-100">Download Videos</p>
                </div>
              </div>

              <motion.button
                className="bg-gradient-to-r from-[#805af5] to-[#cd99ff] text-white font-medium sm:text-lg py-2 sm:py-2 px-3 sm:px-4 rounded-lg  hover:bg-gradient-to-r hover:from-[#6a3ec2] hover:to-[#a68cfc]  w-full leading-[40px] tracking-[0.5px] mt-6 border-0 text-center inline-block"
                whileHover={{ scale: 1.05 }}
              >
                BUY
              </motion.button>



            </div>
          </div>


          {/* card 2 */}
          <div className="h-full relative inline-block p-4  text-white">
            <div className="relative z-10 border-2 border-[#805af5] rounded-xl bg-[#f8f8ff] p-8 h-full shadow-lg hover:shadow-2xl hover:border-[#0d6efd]   ">
              <div ></div>
              <p className="text-black text-[48px] leading-tight block text-center bg-opacity-100 pb-1 font-serif">STARTER</p>
              <h1 className="text-gray-700 mb-5 text-[44px] leading-tight block text-center bg-opacity-100 pb-2 ">
                ₹1597
              </h1>
              <h2 className="text-black text-left pb-2 opacity-100  text-[17px]  font-[500] font-serif mb-4">Features</h2>
              <div className="flex justify-start items-center gap-2">
                <FaCheck className="h-4 w-4 text-green-400" />
                <p className="text-[#55595c]    text-[16px] leading-[1.67] font-[500] bg-opacity-100">
                  Posts 3 Times A Week
                </p>
              </div>

              <div className="flex justify-start items-center gap-2">
                <FaCheck className="h-4 w-4 text-green-400" />
                <p className="text-[#55595c]    text-[16px] leading-[1.67] font-[500] bg-opacity-100">
                  1 Series
                </p>
              </div>
              <div className="flex justify-start items-center gap-2">
                <FaCheck className="h-4 w-4 text-green-400" />
                <p className="text-[#55595c]    text-[16px] leading-[1.67] font-[500] bg-opacity-100">
                  Edit & Preview Videos
                </p>
              </div>
              <div className="flex justify-start items-center gap-2">
                <FaCheck className="h-4 w-4 text-green-400" />
                <p className="text-[#55595c]    text-[16px] leading-[1.67] font-[500] bg-opacity-100">
                  Auto Post To Channel
                </p>
              </div>

              <div className="flex justify-start items-center gap-2">
                <FaCheck className="h-4 w-4 text-green-400" />
                <p className="text-[#55595c]    text-[16px] leading-[1.67] font-[500] bg-opacity-100">
                  HD Video Resolution
                </p>
              </div>
              <div className="flex justify-start items-center gap-2">
                <FaCheck className="h-4 w-4 text-green-400" />
                <p className="text-[#55595c]    text-[16px] leading-[1.67] font-[500] bg-opacity-100">
                  Background Music
                </p>
              </div>
              <div className="flex justify-start items-center gap-2">
                <FaCheck className="h-4 w-4 text-green-400" />
                <p className="text-[#55595c]    text-[16px] leading-[1.67] font-[500] bg-opacity-100">
                  No Watermark
                </p>
              </div>

              <div className="flex justify-start items-center gap-2">
                <FaCheck className="h-4 w-4 text-green-400" />
                <p className="text-[#55595c]    text-[16px] leading-[1.67] font-[500] bg-opacity-100">
                  Download Videos
                </p>
              </div>




              <motion.button
                className="bg-gradient-to-r from-[#805af5] to-[#cd99ff] text-white font-medium sm:text-lg py-2 sm:py-2 px-3 sm:px-4 rounded-lg  hover:bg-gradient-to-r hover:from-[#6a3ec2] hover:to-[#a68cfc]  w-full leading-[40px] tracking-[0.5px] mt-6 border-0 text-center inline-block"
                whileHover={{ scale: 1.05 }}
                onClick={(e) => handlePayment(e, 1597)}
              >
                {" "}
                BUY
              </motion.button>
            </div>
          </div>

          {/* Card 3 */}
          <div className="h-full relative inline-block p-4 text-white">
            <div className="relative z-10 border-2 border-[#805af5] rounded-xl bg-[#f8f8ff] p-8 h-full shadow-lg hover:shadow-2xl hover:border-[#0d6efd] transition-all duration-700 transform clip-top-right ">
              {/* Daily Section */}
              <p className="text-black text-[48px] leading-tight block text-center bg-opacity-100 pb-1 font-serif">DAILY</p>
              <h1 className="text-gray-700 mb-5 text-[44px] leading-tight block text-center bg-opacity-100 pb-2">
                ₹3279
              </h1>

              {/* Features Heading */}
              <h2 className="text-black text-left pb-2 opacity-100 text-[17px] font-[500] font-serif mb-4">Features</h2>

              {/* Features List */}
              <div>
                <div className="flex justify-start items-center gap-2">
                  <FaCheck className="h-4 w-4 text-green-400" />
                  <p className="text-[#55595c] text-[16px] leading-[1.67] font-[500] bg-opacity-100">Posts Once A Day</p>
                </div>
                <div className="flex justify-start items-center gap-2">
                  <FaCheck className="h-4 w-4 text-green-400" />
                  <p className="text-[#55595c] text-[16px] leading-[1.67] font-[500] bg-opacity-100">1 Series</p>
                </div>
                <div className="flex justify-start items-center gap-2">
                  <FaCheck className="h-4 w-4 text-green-400" />
                  <p className="text-[#55595c] text-[16px] leading-[1.67] font-[500] bg-opacity-100">Edit & Preview Videos</p>
                </div>
                <div className="flex justify-start items-center gap-2">
                  <FaCheck className="h-4 w-4 text-green-400" />
                  <p className="text-[#55595c] text-[16px] leading-[1.67] font-[500] bg-opacity-100">Auto Post To Channel</p>
                </div>
                <div className="flex justify-start items-center gap-2">
                  <FaCheck className="h-4 w-4 text-green-400" />
                  <p className="text-[#55595c] text-[16px] leading-[1.67] font-[500] bg-opacity-100">HD Video Resolution</p>
                </div>
                <div className="flex justify-start items-center gap-2">
                  <FaCheck className="h-4 w-4 text-green-400" />
                  <p className="text-[#55595c] text-[16px] leading-[1.67] font-[500] bg-opacity-100">Background Music</p>
                </div>
                <div className="flex justify-start items-center gap-2">
                  <FaCheck className="h-4 w-4 text-green-400" />
                  <p className="text-[#55595c] text-[16px] leading-[1.67] font-[500] bg-opacity-100">No Watermark</p>
                </div>
                <div className="flex justify-start items-center gap-2">
                  <FaCheck className="h-4 w-4 text-green-400" />
                  <p className="text-[#55595c] text-[16px] leading-[1.67] font-[500] bg-opacity-100">Download Videos</p>
                </div>
              </div>

              {/* Buy Button */}
              <motion.button
                className="bg-gradient-to-r from-[#805af5] to-[#cd99ff] text-white font-medium sm:text-lg py-2 sm:py-2 px-3 sm:px-4 rounded-lg hover:bg-gradient-to-r hover:from-[#6a3ec2] hover:to-[#a68cfc] w-full leading-[40px] tracking-[0.5px] mt-6 border-0 text-center inline-block"
                whileHover={{ scale: 1.05 }}
                onClick={(e) => handlePayment(e, 3279)}
              >
                {" "}
                BUY
              </motion.button>
            </div>
          </div>
          {/* card 4 */}
          <div className="h-full relative inline-block p-4 text-white">
            <div className="relative z-10 border-2 border-[#805af5] rounded-xl bg-[#f8f8ff] p-8 h-full shadow-lg hover:shadow-2xl hover:border-[#0d6efd]">
              {/* Card Heading */}
              <p className="text-black text-[48px] leading-tight block text-center bg-opacity-100 pb-1 font-serif">HARDCORE</p>
              <h1 className="text-gray-700 mb-5 text-[44px] leading-tight block text-center bg-opacity-100 pb-2">
                ₹5801
              </h1>

              {/* Features Section */}
              <h2 className="text-black text-left pb-2 opacity-100 text-[17px] font-[500] font-serif mb-4">Features</h2>
              <div className="">
                <div className="flex justify-start items-center gap-2">
                  <FaCheck className="h-4 w-4 text-green-400" />
                  <p className="text-[#55595c] text-[16px] leading-[1.67] font-[500] bg-opacity-100">
                    Posts Twice A Day
                  </p>
                </div>
                <div className="flex justify-start items-center gap-2">
                  <FaCheck className="h-4 w-4 text-green-400" />
                  <p className="text-[#55595c] text-[16px] leading-[1.67] font-[500] bg-opacity-100">
                    1 Series
                  </p>
                </div>
                <div className="flex justify-start items-center gap-2">
                  <FaCheck className="h-4 w-4 text-green-400" />
                  <p className="text-[#55595c] text-[16px] leading-[1.67] font-[500] bg-opacity-100">
                    Edit & Preview Videos
                  </p>
                </div>
                <div className="flex justify-start items-center gap-2">
                  <FaCheck className="h-4 w-4 text-green-400" />
                  <p className="text-[#55595c] text-[16px] leading-[1.67] font-[500] bg-opacity-100">
                    Auto Post To Channel
                  </p>
                </div>
                <div className="flex justify-start items-center gap-2">
                  <FaCheck className="h-4 w-4 text-green-400" />
                  <p className="text-[#55595c] text-[16px] leading-[1.67] font-[500] bg-opacity-100">
                    HD Video Resolution
                  </p>
                </div>
                <div className="flex justify-start items-center gap-2">
                  <FaCheck className="h-4 w-4 text-green-400" />
                  <p className="text-[#55595c] text-[16px] leading-[1.67] font-[500] bg-opacity-100">
                    Background Music
                  </p>
                </div>
                <div className="flex justify-start items-center gap-2">
                  <FaCheck className="h-4 w-4 text-green-400" />
                  <p className="text-[#55595c] text-[16px] leading-[1.67] font-[500] bg-opacity-100">
                    No Watermark
                  </p>
                </div>
                <div className="flex justify-start items-center gap-2">
                  <FaCheck className="h-4 w-4 text-green-400" />
                  <p className="text-[#55595c] text-[16px] leading-[1.67] font-[500] bg-opacity-100">
                    Download Videos
                  </p>
                </div>
              </div>

              {/* Buy Button */}
              <motion.button
                className="bg-gradient-to-r from-[#805af5] to-[#cd99ff] text-white font-medium sm:text-lg py-2 sm:py-2 px-3 sm:px-4 rounded-lg hover:bg-gradient-to-r hover:from-[#6a3ec2] hover:to-[#a68cfc] w-full leading-[40px] tracking-[0.5px] mt-6 border-0 text-center inline-block"
                whileHover={{ scale: 1.05 }}
                onClick={(e) => handlePayment(e, 5801)}
              >
                {" "}
                BUY
              </motion.button>
            </div>
          </div>

        </div>
        {/* Modal */}
        {isModalOpen && <PaymentModal />}
      </div>
    </div>
  );
};

export default Billing;
