import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { Search, ShoppingCart, User } from 'lucide-react';
import { useCart } from '../../provider/TemplateCartContext';

export default function Navbar() {
  const [isCartOpen, setIsCartOpen] = useState(false);
  const { cartItems, removeFromCart } = useCart(); // Access removeFromCart function

  const toggleCart = () => {
    setIsCartOpen(!isCartOpen);
  };

  return (
    <nav className="bg-white shadow-sm fixed top-0 left-0 w-full z-50">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="flex justify-between h-16">
          <div className="flex items-center">
            <Link to="/" className="flex-shrink-0 flex items-center">
              <h1 className="text-2xl font-bold text-indigo-600">TemplateHub</h1>
            </Link>
          </div>

          <div className="flex-1 flex items-center justify-center px-2 lg:ml-6 lg:justify-end">
            <div className="max-w-lg w-full lg:max-w-xs">
              <label htmlFor="search" className="sr-only">Search</label>
              <div className="relative">
                <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                  <Search className="h-5 w-5 text-gray-400" />
                </div>
                <input
                  id="search"
                  className="block w-full pl-10 pr-3 py-2 border border-gray-300 rounded-md leading-5 bg-white placeholder-gray-500 focus:outline-none focus:placeholder-gray-400 focus:ring-1 focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                  placeholder="Search templates..."
                  type="search"
                />
              </div>
            </div>
          </div>

          <div className="flex items-center">
            <Link to="/marketplace" className="p-2 text-gray-600 hover:text-gray-900">
              Marketplace
            </Link>
            <button
              onClick={toggleCart}
              className="p-2 text-gray-600 hover:text-gray-900 relative"
            >
              <ShoppingCart className="h-6 w-6" />
              {cartItems.length > 0 && (
                <span className="absolute top-0 right-0 block w-4 h-4 text-xs text-white bg-red-600 rounded-full">
                  {cartItems.length}
                </span>
              )}
            </button>
            <Link to="/marketplace/buyer/dashboard" className="ml-4 p-2 text-gray-600 hover:text-gray-900">
              <User className="h-6 w-6" />
            </Link>
          </div>
        </div>
      </div>

      {/* Cart Sidebar */}
      {isCartOpen && (
        <div className="fixed inset-0 z-50 flex items-center justify-end bg-black bg-opacity-50">
          <div className="w-[350px] bg-white h-full shadow-lg transform translate-x-0 transition-all ease-in-out duration-300">
            <div className="flex justify-between items-center p-4 border-b">
              <h2 className="text-xl font-semibold text-center text-gray-800">Your Cart</h2>
              <button onClick={toggleCart} className="text-gray-500 text-xl">
                &times;
              </button>
            </div>

            <div className="p-4 max-h-full overflow-y-auto">
              {cartItems.length > 0 ? (
                <div>
                  {cartItems.map((item) => (
                    <div
                      key={item.id}
                      className="flex flex-col bg-gray-100 p-3 mb-4 rounded-lg shadow-sm"
                    >
                      <div className="flex items-center">
                        <img
                          src={item.thumbnail}
                          alt={item.title}
                          className="w-12 h-12 rounded-md object-cover mr-4"
                        />
                        <div className="flex-1">
                          <h3 className="text-sm font-semibold text-gray-900">{item.title}</h3>
                          <p className="text-xs text-gray-500">{item.category}</p>
                          <div className="flex items-center mt-1">
                            {[...Array(5)].map((_, index) => (
                              <span
                                key={index}
                                className={`${index < item.rating
                                  ? 'text-yellow-400'
                                  : 'text-gray-300'
                                  } text-xs`}
                              >
                                ★
                              </span>
                            ))}
                          </div>
                          <p className="text-sm text-gray-900 mt-2">₹{item.price}</p>
                        </div>
                      </div>

                      <div className="flex justify-end gap-x-1 items-center">
                        {/* View Details Link */}
                        <Link
                          to={`/marketplace/template/${item.id}`}
                          className="text-sm text-indigo-600 hover:text-indigo-800"
                          onClick={toggleCart} // Close cart when "View Details" is clicked
                        >
                          View Details
                        </Link>

                        {/* Remove Item Button */}
                        <button
                          onClick={() => removeFromCart(item.id)} // Call removeFromCart function
                          className="text-sm text-red-500 hover:text-red-700"
                        >
                          <span className="cursor-pointer">Remove</span>
                        </button>
                      </div>
                    </div>
                  ))}
                </div>
              ) : (
                <p className="text-gray-500">Your cart is empty.</p>
              )}
            </div>
          </div>
        </div>
      )}
    </nav>
  );
}
