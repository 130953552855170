import { useContext, useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { AuthContext } from "../provider/AuthProvider";
import axios from "axios";
import { serverbaseURL } from "../constant/index";
import { narrationOptions } from "../constant/index.jsx";
import { motion } from 'framer-motion';


const View = () => {
  const { user, userPlan, setLoading } = useContext(AuthContext);
  const [seriesData, setSeriesData] = useState([]);
  const [googleId, setGoogleId] = useState("");
  const [taskId, setTaskId] = useState("");
  const navigate = useNavigate();


  useEffect(() => {
    const fetchSeriesData = async () => {
      setLoading(true);
      try {
        const response = await axios.get(
          `${serverbaseURL}series_info?email=${user?.email}`
        );
        setSeriesData(response?.data);
      } catch (error) {
        console.error("error", error);
      } finally {
        //   if(seriesData.length == 0) {
        //     seriesData.push( {
        //       "_id": "6437a8f9c2e1f23d4b5e6f7a",
        //       "channelType": "History",
        //       "imageGenPrompt": "What is your favorite image?",
        //       "storyPrompt": "What is your favorite movie?",
        //       "imageGenTool": "Midjourney",
        //       "voiceModel": "Default",
        //       "imageSlideCount": 1,
        //       "whereToPost": "email",
        //       "content": "Introduction to JavaScript",
        //       "language": "English",
        //       "generations": 1
        //   },{
        //     "_id": "6437a8f9c2e1f23d12317a",
        //     "channelType": "History",
        //     "imageGenPrompt": "What is your favorite image?",
        //     "storyPrompt": "What is your favorite movie?",
        //     "imageGenTool": "Midjourney",
        //     "voiceModel": "Default",
        //     "imageSlideCount": 1,
        //       "whereToPost": "email",
        //     "content": "Introduction to JavaScript",
        //     "language": "English",
        //     "generations": 4
        // });
        //   }
        setLoading(false);
      }
    };
    fetchSeriesData();
  }, [userPlan?.email]);

  // Function to find the narrator name by ID
  const getNarratorNameById = (id) => {
    const narrator = narrationOptions.find((option) => option.id === id);
    return narrator ? narrator.name : "Unknown Narrator";
  };

  const handleConnectYoutube = (item) => {
    sessionStorage.setItem("taskId", item._id);
    window.location.href = `${serverbaseURL}connect_youtube`;
  };

  useEffect(() => {
    const params = new URLSearchParams(window.location.search);

    const updateGoogleIdInDB = async () => {
      // Extracting googleId from URL
      const params = new URLSearchParams(window.location.search);
      const googleIdFromUrl = params.get("googleId");
      if (googleIdFromUrl) {
        setGoogleId(googleIdFromUrl);
        window.history.replaceState(null, null, window.location.pathname);
      }

      const taskIdFromStorage = sessionStorage.getItem("taskId");
      if (taskIdFromStorage) {
        setTaskId(taskIdFromStorage);
      }

      if (googleIdFromUrl && taskIdFromStorage) {
        try {
          const response = await axios.patch(
            `${import.meta.env.VITE_BACKEND
            }/googleId?taskId=${taskIdFromStorage}`,
            {
              googleId: googleIdFromUrl,
            }
          );
        } catch (error) {
          console.error("Error making PATCH request:", error);
        }
      }
    };
    updateGoogleIdInDB();
  }, []);

  const handleScheduleVideo = async (item) => {
    if (!googleId) {
      alert("Connect a google account for the series.");
      return;
    }
    try {
      const response = await axios.post(`${serverbaseURL}get_Generations`, {
        email: userPlan?.email,
        seriesId: item._id,
        postADay: 1,
        googleId,
      });
      alert(`${response.data.message}`);
    } catch (error) {
      alert(`${error}`);
      console.error(error);
    }
  };

  const handleEditVideo = (item) => {
    navigate("/dashboard/edit-video", { state: { item } });
  };

  const handleViewGenerations = (item) => {
    navigate("/dashboard/viewGenerations", { state: { item } });
  };
  return (
    <div className="  h-screen w-full pt-4 bg-gradient-to-b from-white via-[#f1f5f9] to-[#e2e8f0]
">
      <div className="max-w-6xl  mx-auto p-8">
        <div className="flex justify-between items-center pb-3">
          <h1 className="text-2xl font-bold text-black">YOUR CHANNELS</h1>
          <Link to="/dashboard/create">
            <button className="bg-gradient-to-r from-[#805af5] to-blue-600 text-white py-2 px-5 rounded-md text-lg mr-11 font-semibold hover:shadow-lg transition duration-200">
              ADD CHANNEL
            </button>
          </Link>
        </div>
        <div className="pb-10">
          <hr className="h-[2px] bg-gray-600 " />
        </div>

        {seriesData.length > 0 ? (
          <div className="overflow-x-auto">
            <table className="min-w-full bg-white border border-[#805af5] rounded-lg shadow-md overflow-hidden">
              <thead>
                <tr className="bg-[#805af5] text-white">
                  <th className="py-3 px-6 text-left font-semibold text-sm uppercase tracking-wider">Topic</th>
                  <th className="py-3 px-6 text-left font-semibold text-sm uppercase tracking-wider">Language</th>
                  <th className="py-3 px-6 text-left font-semibold text-sm uppercase tracking-wider">Narrator</th>
                  <th className="py-3 px-6 text-left font-semibold text-sm uppercase tracking-wider">Where to Post</th>
                  <th className="py-3 px-6 text-left font-semibold text-sm uppercase tracking-wider">Generated</th>
                  <th className="py-3 px-6 text-center font-semibold text-sm uppercase tracking-wider">Actions</th>
                </tr>
              </thead>
              <tbody className="divide-y divide-gray-200">
                {seriesData.map((item) => (
                  <tr key={item?._id} className="hover:bg-gray-50">
                    <td className="py-4 px-6  text-gray-700 text-sm">{item?.topic}</td>
                    <td className="py-4 px-6 text-gray-700 text-sm">{item?.language}</td>
                    <td className="py-4 px-6 text-gray-700 text-sm">{getNarratorNameById(item?.narrator)}</td>
                    <td className="py-4 px-6 text-gray-700 text-sm">Email</td>
                    <td className="py-4 px-6 text-gray-700 text-sm">{item?.Vidcount || 1}</td>
                    <td className="py-4 px-6 text-gray-700 text-sm">
                      <div className="flex flex-col space-y-2 sm:flex-row sm:space-x-2 sm:space-y-0">
                        <button
                          className="bg-gradient-to-r from-[#805af5] to-blue-600 text-white py-2 px-4 rounded-md text-sm font-semibold hover:shadow-lg transition duration-200"
                          onClick={() => handleViewGenerations(item)}
                        >
                          Generations
                        </button>
                        <button
                          className="bg-gradient-to-r from-[#805af5] to-blue-600 text-white py-2 px-4 rounded-md text-sm font-semibold hover:shadow-lg transition duration-200"
                          onClick={() => handleEditVideo(item)}
                        >
                          Edit / Stop
                        </button>
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>

          </div>
        ) : (
          <motion.div className="p-8 w-full md:px-16 shadow-xl rounded-lg bg-[#805af5]"
            initial={{ opacity: 0, x: -1000, scale: 0.95 }} // Starts off-screen to the left with slight scale down
            animate={{ opacity: 1, x: 0, scale: 1 }} // Animates to full opacity and position with scale up
            transition={{
              duration: 0.4, // Duration for smooth transition
              ease: 'easeInOut', // Smooth easing function for smooth and natural animation
              delay: 0.1, // A slight delay for a more fluid effect
            }}
          >
            <p className="text-white text-xl ml-6  mt-3 font-bold pb-3">
              You haven't started a Faceless Video channel yet.
            </p>

            <Link to="/dashboard/create" >
              <button className=" text-white py-3 px-6 text-lg font-semibold mb-5">
                CREATE YOUR CHANNEL
              </button>
            </Link>
          </motion.div>
        )}
      </div>
    </div>
  );
};

export default View;
