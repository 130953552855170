// import { googleLogout } from '@react-oauth/google';
import React, { useEffect, useRef, useState, useContext } from 'react'
import { GiDoctorFace, GiHamburgerMenu } from 'react-icons/gi';
import { Link, useNavigate } from 'react-router-dom';
import logo from '../assets/logo.png';
import { AuthContext } from '../provider/AuthProvider';
import { motion } from "framer-motion";


const Dashnav = () => {
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [isScrolled, setIsScrolled] = useState(false);

  const { logOut } = useContext(AuthContext);
  const dropdownRef = useRef(null);
  const navigate = useNavigate()


  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 20) {
        setIsScrolled(true); // Set to true when scrolled past 100px
      } else {
        setIsScrolled(false);
      }
    };
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);


  const toggleDropdown = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };

  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setIsDropdownOpen(false);
    }
  };


  const handleLogout = () => {
    logOut(); // Call the logOut function from AuthContext
    navigate('/');
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <div
      className={`w-full  bg-white px-14 ${isScrolled ? 'fixed top-0 left-0 z-50 shadow-md transition duration-300 bg-white border-b-2 border-gray-200' : ''}`}
    >
      <div className={`flex justify-between items-center py-3 ${isScrolled ? 'bg-white' : 'bg-light-blue-100'}`}>
        {/* Logo and Brand */}
        <Link to="/" className="flex items-center gap-3">
          <img src={logo} alt="Logo" className="w-10 h-auto" />
          <h1 className="text-2xl font-semibold text-gray-800">AutoMovieCreator</h1>
        </Link>

        {/* Desktop Links */}
        <div className="hidden lg:flex space-x-6 items-center">
          {['Dashboard', 'Create', 'Billing'].map((item, index) => (
            <motion.div
            key={item}
            className="group relative inline-block"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ delay: 0.2 * (index + 1) }}
          >
            <Link
              to={`/dashboard${item === 'Dashboard' ? '' : `/${item.toLowerCase()}`}`}
              className="text-gray-600 text-lg font-semibold hover:text-gray-800 p-2 rounded-full transition duration-300 ease-in-out group-hover:bg-gray-200"
            >
              {item}
            </Link>
          </motion.div>
          ))}
          <motion.div
            className="group relative inline-block"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ delay: 1 }}
          >
            <button
              onClick={handleLogout}
              className="text-red-600 text-lg font-semibold hover:text-red-800 p-2 rounded-full transition duration-300 ease-in-out group-hover:bg-gray-200"
            >
              Logout
            </button>
          </motion.div>
        </div>

        {/* Mobile Menu Toggle */}
        <div className="lg:hidden relative" ref={dropdownRef}>
          <button onClick={toggleDropdown} className="text-xl text-gray-600 p-2">
            <GiHamburgerMenu />
          </button>

          {isDropdownOpen && (
            <motion.div
              initial={{ opacity: 0, y: -10 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.3 }}
              className="absolute top-10 right-0 w-40 bg-white border border-gray-200 rounded-lg shadow-lg"
            >
              {['Dashboard', 'Create', 'Billing'].map((item) => (
                <Link
                  key={item}
                  to={`/dashboard/${item.toLowerCase()}`}
                  className="block text-lg px-4 py-2 text-gray-600 font-semibold hover:text-gray-800 hover:bg-gray-100 transition-colors duration-300"
                >
                  {item}
                </Link>
              ))}
              <button
                onClick={handleLogout}
                className="block text-lg w-full px-4 py-2  font-semibold text-left text-red-600 hover:text-red-800 hover:bg-gray-100 transition-colors duration-300"
              >
                Logout
              </button>
            </motion.div>
          )}
        </div>
      </div>
    </div>
  )
}

export default Dashnav