import React from 'react';
import { Outlet } from 'react-router-dom';
import Navbar from '../components/MarketPlace/Navbar';
import Footer from '../components/MarketPlace/Footer';
import { CartProvider } from '../provider/TemplateCartContext';

export const MarketPlaceLayout = () => {
  return (
    <CartProvider>

      <div className="min-h-screen bg-gray-50 flex flex-col">

        <Navbar />
        <Outlet />
        <Footer />

      </div>
    </CartProvider>

  );
};

export default MarketPlaceLayout;
