
import React, { useContext } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { AuthContext } from "../../provider/AuthProvider";

export default function Footer() {
  const navigate = useNavigate();
  const { user } = useContext(AuthContext);
  const id = user.uid;

  const scrollToTopAndNavigate = (path) => {
    window.scrollTo(0, 0); // Scroll to top
    navigate(path); // Navigate to the specified path
  };

  return (
    <footer className="bg-white border-t border-gray-200">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-12">
        <div className="grid grid-cols-1 md:grid-cols-4 gap-8">
          <div>
            <h3 className="text-lg font-bold text-indigo-600 mb-4">TemplateHub</h3>
            <p className="text-gray-600 text-sm">
              The marketplace for high-quality website and app templates.
            </p>
          </div>

          <div>
            <h4 className="font-medium text-gray-900 mb-4">For Buyers</h4>
            <ul className="space-y-2">
              <li>
                <Link to="/marketplace" className="text-gray-600 hover:text-gray-900 text-sm">
                  Browse Templates
                </Link>
              </li>
              <li>
                <span
                  onClick={() => scrollToTopAndNavigate('/marketplace/buyer/dashboard')}
                  className="text-gray-600 hover:text-gray-900 text-sm cursor-pointer"
                >
                  My Purchases
                </span>
              </li>
            </ul>
          </div>

          <div>
            <h4 className="font-medium text-gray-900 mb-4">For Sellers</h4>
            <ul className="space-y-2">
              <li>
                <span
                  onClick={() => scrollToTopAndNavigate('/marketplace/seller/dashboard')}
                  className="text-gray-600 hover:text-gray-900 text-sm cursor-pointer"
                >
                  My Purchases
                </span>
              </li>
              <li>
                <Link to={`/marketplace/seller/${id}`} className="text-gray-600 hover:text-gray-900 text-sm">
                  Upload Template
                </Link>
              </li>
            </ul>
          </div>

          <div>
            <h4 className="font-medium text-gray-900 mb-4">Support</h4>
            <ul className="space-y-2">
              <li>
                <Link to="/help" className="text-gray-600 hover:text-gray-900 text-sm">
                  Help Center
                </Link>
              </li>
              <li>
                <Link to="/contact" className="text-gray-600 hover:text-gray-900 text-sm">
                  Contact Us
                </Link>
              </li>
            </ul>
          </div>
        </div>

        <div className="mt-8 pt-8 border-t border-gray-200">
          <p className="text-center text-gray-500 text-sm">
            © {new Date().getFullYear()} TemplateHub. All rights reserved.
          </p>
        </div>
      </div>
    </footer>
  );
}
