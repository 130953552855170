import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Star } from 'lucide-react';
import TemplateCard from '../../components/MarketPlace/TemplateCard';
import { serverbaseURL } from "../../constant/index";
import axios from 'axios';
import { Mail } from 'lucide-react';

export default function SellerProfile() {
  const { id } = useParams();
  const [seller, setSeller] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);


  useEffect(() => {
    const fetchSellerData = async () => {
      try {
        const response = await axios.get(`${serverbaseURL}user/${id}`);
        const data = response.data;

        // Map backend response to match the frontend's expected structure
        const formattedSeller = {
          sellerId: data.sellerId,
          name: data.creatorName,
          email: data.email,
          brandLogoLink: data.brandLogo,
          rating: data.rating,
          reviews: data.templates.length, // Assuming the number of templates represents reviews
          templates: data.templates.map(template => ({
            id: template.id,
            title: template.title,
            description: template.description,
            price: template.price,
            rating: template.rating || 0,
            category: template.category,
            creator: { name: data.creatorName, id: data.sellerId },
            thumbnail: template.thumbnail || 'No thumbnail available'
          })),
        };
        setSeller(formattedSeller);
        setLoading(false);
      } catch (error) {
        setError('Failed to load seller data');
        setLoading(false);
      }
    };
    fetchSellerData();
  }, [id]);

  if (loading) return <p>Loading...</p>;
  if (error) return <p>{error}</p>;

  return (
    <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-8">
      <div className="bg-white rounded-lg shadow-sm p-6 mb-8">
        <div className="flex items-center">
          <img
            src={seller.brandLogoLink}
            alt={seller.name}
            className="h-20 w-20 rounded-full"
          />
          <div className="ml-6">
            <h1 className="text-2xl font-bold text-gray-900">{seller.name}</h1>
            <div className="flex items-center mt-2">
              <Star className="h-5 w-5 text-yellow-400 fill-current" />
              <span className="ml-1 text-lg font-medium text-gray-900">{seller.rating}</span>
              <span className="ml-1 text-sm text-gray-500">(11 reviews)</span>
            </div>
            <div className="flex items-center bg-white p-4 rounded-lg shadow-md hover:shadow-lg transition duration-300">
              <Mail className="h-6 w-6 text-gray-700" />
              <span className="ml-3 text-lg text-gray-800 font-medium">{seller.email}</span>
              <a
                href={`mailto:${seller.email}`}
                className="ml-auto p-2 bg-blue-600 text-white rounded-lg mr-3 shadow-md hover:bg-blue-700 transition duration-300 flex items-center"
              >
                Contact Now
              </a>
            </div>
          </div>
        </div>
      </div>

      <h2 className="text-xl font-bold text-gray-900 mb-6">Templates by {seller.name}</h2>
      <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6">
        {seller.templates.map((template) => (
          <TemplateCard key={template.id} {...template} />
        ))}
      </div>
    </div>
  );
}
