import { useState } from 'react';
import { serverbaseURL } from "../constant/index";
import { Download, Edit2 } from 'lucide-react';
import axios from 'axios';
import { motion } from 'framer-motion';

const platformRequirements = {
  YouTube: {
    banner: { width: 1024, height: 1024 },
    profileImage: { width: 256, height: 256 },
    aboutLength: 1000,
    description: "Required assets for YouTube channel customization",
  },
  Instagram: {
    profileImage: { width: 256, height: 256 },
    aboutLength: 150,
    description: "Required assets for Instagram profile setup",
  },
  Facebook: {
    banner: { width: 1024, height: 1024 },
    profileImage: { width: 256, height: 256 },
    aboutLength: 255,
    description: "Required assets for Facebook page branding",
  },
  TikTok: {
    profileImage: { width: 256, height: 256 },
    aboutLength: 80,
    description: "Required assets for TikTok profile setup",
  },
  Twitter: {
    banner: { width: 1024, height: 1024 },
    profileImage: { width: 256, height: 256 },
    aboutLength: 160,
    description: "Required assets for Twitter profile customization",
  }
};

const RequirementCard = ({ title, details }) => (
  <motion.div
    className="mb-6 z-10 border-2 border-[#805af5] bg-[#f8f8ff] shadow-lg rounded-xl p-6 transition-all duration-300 transform hover:scale-105"
    initial={{ opacity: 0, y: 20 }}
    animate={{ opacity: 1, y: 0 }}
    transition={{ duration: 0.5 }}
  >
    <h3 className="text-2xl font-semibold text-black">{title}</h3>
    <div className="mt-4 space-y-3">
      {Object.entries(details).map(([key, value]) => {
        if (key !== 'description') {
          return (
            <motion.div
              key={key}
              className="flex items-center gap-3 text-gray-800"
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              transition={{ duration: 0.4 }}
            >
              <span className="font-medium text-[#1a237e]">{key.replace(/([A-Z])/g, ' $1').trim()}:</span>
              {typeof value === 'object' ? (
                <span className="text-gray-600">{value.width} × {value.height}px</span>
              ) : (
                <span className="text-gray-600">{value} characters</span>
              )}
            </motion.div>
          );
        }
        return null;
      })}
    </div>
  </motion.div>
);

const AssetCard = ({ title, width, height, imageSrc }) => (
  <motion.div
    className="mb-6 bg-gradient-to-r from-[#64b5f6] to-[#42a5f5] shadow-lg rounded-xl p-5 font-serif transition-all duration-300 transform hover:scale-105"
    initial={{ opacity: 0, y: 20 }}
    animate={{ opacity: 1, y: 0 }}
    transition={{ duration: 0.6 }}
  >
    <h3 className="text-2xl font-semibold text-white">{title}</h3>
    <p className="text-sm text-gray-100">Required dimensions: {width} × {height}</p>

    <div className="relative bg-gray-200 rounded-lg overflow-hidden mt-4">
      <motion.img
        src={imageSrc || "/api/placeholder/400/300"}
        alt={title}
        className="w-full h-48 object-cover filter brightness-90 hover:brightness-100 transition duration-300"
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 0.5, delay: 0.2 }}
      />
      <div className="absolute top-3 right-3 flex space-x-2">
        <motion.button
          className="bg-[#303f9f] text-white rounded-full p-2 flex items-center hover:bg-[#3949ab] transition duration-200 shadow-md"
          whileHover={{ scale: 1.1 }}
          transition={{ duration: 0.3 }}
        >
          <Download className="w-5 h-5" />
        </motion.button>

        <motion.button
          className="bg-[#303f9f] text-white rounded-full p-2 flex items-center hover:bg-[#3949ab] transition duration-200 shadow-md"
          whileHover={{ scale: 1.1 }}
          transition={{ duration: 0.3 }}
        >
          <Edit2 className="w-5 h-5" />
        </motion.button>
      </div>
    </div>
  </motion.div>
);

const ChannelAssets = () => {
  const [channelName, setChannelName] = useState('');
  const [channelDescription, setChannelDescription] = useState('');
  const [selectedPlatform, setSelectedPlatform] = useState('');
  const [isGenerating, setIsGenerating] = useState(false);
  const [generatedAssets, setGeneratedAssets] = useState(null);
  const [isDescriptionFocused, setIsDescriptionFocused] = useState(false);

  const handleGenerate = async () => {
    setIsGenerating(true);

    try {
      const platformDetails = platformRequirements[selectedPlatform];
      // const response = await axios.post(`${serverbaseURL}generate-assets`, {
      //   channelName,
      //   description: channelDescription,
      //   platform: selectedPlatform,
      //   banner: platformDetails.banner,          
      //   profileImage: platformDetails.profileImage, 
      //   aboutLength: platformDetails.aboutLength,
      // });

      const requestBody = {
        channelName,
        description: channelDescription,
        platform: selectedPlatform,
        profileImage: platformDetails.profileImage,
        aboutLength: platformDetails.aboutLength,
      };

      // Conditionally add banner if it exists for the selected platform
      if (platformDetails.banner) {
        requestBody.banner = platformDetails.banner;
      }

      // Send request to the backend
      const response = await axios.post(`${serverbaseURL}generate-assets`, requestBody);

      const { profileImage, description, bannerImage } = response.data;

      setGeneratedAssets({
        about: description,
        images: {
          banner: bannerImage || "/api/placeholder/800/400",
          profile: profileImage || "/api/placeholder/800/400",
        }
      });
    } catch (error) {
      console.error("Error generating assets:", error);
    } finally {
      setIsGenerating(false);
    }
  };

  const isFormValid = channelName.trim() && channelDescription.trim() && selectedPlatform;

  return (
    <motion.div className="max-w-4xl my-8 mx-auto p-6 "
      initial={{ opacity: 0, x: -1000, scale: 0.95 }} // Starts off-screen to the left with slight scale down
      animate={{ opacity: 1, x: 0, scale: 1 }} // Animates to full opacity and position with scale up
      transition={{
        duration: 0.4, // Duration for smooth transition
        ease: 'easeInOut', // Smooth easing function for smooth and natural animation
        delay: 0.1, // A slight delay for a more fluid effect
      }}>

      <div
        className=" rounded-t-3xl mb-16 mt-7 bg-gradient-to-b from-[#ebefff] via-[#805af5] to-[#d9e0fd] shadow-lg transition-all duration-300 ease-linear  hover:shadow-2xl p-6 px-6 "
      >

        <motion.h2
          className=" sm:text-2xl font-bold text-black opacity-100 transform-none delay-200 transition-opacity text-center  text-5xl mb-1 leading-tight break-words font-custom"
          initial={{ scale: 0.9 }}
          animate={{ scale: 1 }}
          transition={{ duration: 0.4 }}
        >
          Channel Assets Generator
        </motion.h2>
        <p className="text-[#55595c] opacity-100  font-sans text-center mb-6 transform-none delay-200 transition-opacity   leading-tight break-words">
          Enter your channel details and select a platform
        </p>
        <div className="space-y-6 mt-4">
          <div className="space-y-4">
            <div className="space-y-2">
              <label htmlFor="channelName" className="block text-black">
                Channel Name
              </label>
              <motion.input
                id="channelName"
                placeholder="Enter your channel name"
                value={channelName}
                onChange={(e) => setChannelName(e.target.value)}
                className="border rounded-md p-3 w-full focus:ring-2 focus:ring-[#6a48f2] bg-white"
                whileFocus={{ scale: 1.05 }}
                transition={{ duration: 0.3 }}
              />
            </div>

            <div className="space-y-2">
              <label htmlFor="channelDescription" className="block text-black">
                Brief Description
              </label>
              <motion.textarea
                id="channelDescription"
                placeholder="Describe your channel's content and purpose"
                value={channelDescription}
                onChange={(e) => setChannelDescription(e.target.value)}
                onFocus={() => setIsDescriptionFocused(true)}
                onBlur={() => setIsDescriptionFocused(false)}
                className={`min-h-[100px] border rounded-md p-3 w-full bg-white ${isDescriptionFocused ? 'border-black' : 'border-gray-300'} focus:ring-2 focus:ring-[#6a48f2]`}
                whileFocus={{ scale: 1.05 }}
                transition={{ duration: 0.3 }}
              />
            </div>
          </div>

          <div className="space-y-2 mb-8">
            <label className="block text-black">Select Platform</label>
            <motion.select
              value={selectedPlatform}
              onChange={(e) => setSelectedPlatform(e.target.value)}
              className="w-full border rounded-md p-3 focus:ring-2 focus:ring-[#6a48f2] bg-white"
              whileHover={{ scale: 1.05 }}
              transition={{ duration: 0.3 }}
            >
              <option value="">Choose a social media platform</option>
              {Object.keys(platformRequirements).map((platform) => (
                <option key={platform} value={platform}>
                  {platform}
                </option>
              ))}
            </motion.select>
          </div>
        </div>
      </div>

      {selectedPlatform && !generatedAssets && (
        <motion.div
          className="space-y-6"
          initial={{ opacity: 0, x: 1000 }}
          animate={{ opacity: 1, x: 0 }}
          transition={{ duration: 0.5 }}
        >
          <motion.div
            className="bg-[#ebefff] font-sans shadow-lg rounded-lg p-6"
            whileHover={{ scale: 1.03 }}
            transition={{ duration: 0.3 }}
          >
            <motion.h3
              className=" text-black sm:text-xl opacity-100 transform-none delay-200 transition-opacity text-center font-[500] text-[24px]  leading-tight break-words font-sans bg-clip-text bg-gradient-to-r from-[#6a48f2] to-[#cd99ff] mb-1"
              initial={{ scale: 0.9 }}
              animate={{ scale: 1 }}
              transition={{ duration: 0.4 }}
            >
              Required Assets for {selectedPlatform}
            </motion.h3>

            <p className="text-[#55595c] opacity-100  font-sans text-center mb-6 transform-none delay-200 transition-opacity   leading-tight break-words">
              {platformRequirements[selectedPlatform].description}
            </p>

            <RequirementCard
              title="Asset Requirements"
              details={platformRequirements[selectedPlatform]}
            />

            <motion.button
              onClick={handleGenerate}
              disabled={isGenerating || !channelName || !channelDescription || !selectedPlatform}
              className="mt-4 w-full bg-gradient-to-r from-[#805af5] to-[#cd99ff] text-white font-medium text-base sm:text-lg py-3 rounded-lg hover:shadow-lg transition duration-400 ease-in-out leading-[50px] tracking-[0.5px] font-serif text-center disabled:bg-gray-300"
              whileHover={{ scale: 1.02 }}
              transition={{ duration: 0.3 }}
            >
              {isGenerating ? 'Generating...' : 'Generate Assets'}
            </motion.button>
          </motion.div>
        </motion.div>
      )}

      {generatedAssets && (
        <motion.div
          className="space-y-6"
          initial={{ opacity: 0, y: 1000 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5 }}
        >
          <h2 className="text-lg sm:text-xl font-semibold text-transparent bg-clip-text bg-gradient-to-r from-[#6a48f2] to-[#cd99ff] mb-3 text-center">
            Generated Assets
          </h2>

          {platformRequirements[selectedPlatform].banner && (
            <AssetCard
              title="Banner Image"
              width={platformRequirements[selectedPlatform].banner.width}
              height={platformRequirements[selectedPlatform].banner.height}
              imageSrc={generatedAssets.images.banner}
            />
          )}

          <AssetCard
            title="Profile Image"
            width={platformRequirements[selectedPlatform].profileImage.width}
            height={platformRequirements[selectedPlatform].profileImage.height}
            imageSrc={generatedAssets.images.profile}
          />

          <motion.div
            className="bg-white shadow-lg rounded-lg p-5"
            whileHover={{ scale: 1.05 }}
            transition={{ duration: 0.3 }}
          >
            <h3 className="text-lg sm:text-xl font-semibold text-transparent bg-clip-text bg-gradient-to-r from-[#6a48f2] to-[#cd99ff] text-center">
              About Section
            </h3>
            <p className="text-sm text-[#55595c] text-center">Maximum length: {platformRequirements[selectedPlatform].aboutLength} characters</p>
            <div className="bg-gray-50 p-4 rounded-lg mt-2 border text-gray-700">
              {generatedAssets.about}
            </div>
          </motion.div>
        </motion.div>
      )}
    </motion.div>


  );
};

export default ChannelAssets;
