import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { Star } from 'lucide-react';
import { useCart } from '../../provider/TemplateCartContext';

export default function TemplateCard({
  id,
  title,
  price,
  rating,
  category,
  creator = {},
  thumbnail,
  sellerId,
}) {
  const { addToCart } = useCart();
  const [showPopup, setShowPopup] = useState(false);

  const handleAddToCart = () => {
    const cartItem = { id, rating, category, title, price, thumbnail };
    addToCart(cartItem);

    // Show the popup for confirmation
    setShowPopup(true);
    // Hide the popup after 2 seconds
    setTimeout(() => setShowPopup(false), 2000);
  };

  return (
    <div className="bg-white rounded-lg shadow-sm hover:shadow-md transition-shadow duration-200 relative">
      <Link to={`/marketplace/template/${id}`}>
        <div className="relative aspect-video">
          <img
            src={thumbnail}
            alt={title}
            className="w-full h-full object-cover rounded-t-lg"
          />
        </div>
        <div className="p-4">
          <div className="flex items-center justify-between mb-2">
            <span className="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-indigo-100 text-indigo-800">
              {category}
            </span>
            <div className="flex items-center">
              <Star className="h-4 w-4 text-yellow-400 fill-current" />
              <span className="ml-1 text-sm text-gray-600">{rating}</span>
            </div>
          </div>
          <h3 className="text-lg font-medium text-gray-900 mb-1">{title}</h3>
          <div className="flex items-center justify-between">
            <Link
              to={`/marketplace/seller/${sellerId}`}
              className="text-sm text-gray-600 hover:text-indigo-600"
            >
              {creator?.name || 'Unknown Creator'}
            </Link>
            <span className="text-lg font-bold text-indigo-600">₹{price}</span>
          </div>
        </div>
      </Link>
      <button
        onClick={handleAddToCart}
        className="mt-2 px-4 py-2 bg-indigo-600 text-white rounded hover:bg-indigo-700 transition-colors duration-200"
      >
        Add to Cart
      </button>

      {/* Popup notification */}
      {showPopup && (
        <div className="absolute bottom-14 left-1/2 transform -translate-x-1/2 bg-indigo-600 text-white px-4 py-2 rounded shadow-md">
          Item added to cart!
        </div>
      )}
    </div>
  );
}
