import React, { useState } from "react";
import { useContext } from "react";
import { AuthContext } from "../provider/AuthProvider.jsx";
import Modal from 'react-modal';
import { motion } from 'framer-motion';
import {
  Send,
  PlayCircle,
  Globe,
  Mic,
  Clock,
  ChevronRight,
  ChevronLeft,
  Check,
} from "lucide-react";
import {
  contentOptions,
  destinationOptions,
  languageOptions,
  durationOptions,
  serverbaseURL,
  narrationOptions,
  ChannelPrompts,
} from "../constant/index.jsx";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { CgSpinner } from "react-icons/cg";

const VideoCreationWizard = () => {
  const [step, setStep] = useState(1);
  const [isLoading, setIsLoading] = useState(false);
  const [formData, setFormData] = useState({
    destination: "",
    topic: "",
    language: "",
    narrator: "",
    duration: "",
  });
  const { user, googleSignIn, setPostLoginCallback, setUser, setLoading } =
    useContext(AuthContext);
  const navigate = useNavigate();
  const [showAdvanced, setShowAdvanced] = useState(false);
  const [advancedData, setAdvancedData] = useState({
    channelType: "",
    storyPrompt: "",
    imageGenPrompt: "",
    imageGenTool: "",
    voiceModel: "",
    imageSlideCount: "",
    whereToPost: "email", // Default value
    language: "English", // Default value
  });

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedVideoUrl, setSelectedVideoUrl] = useState(null);
  const [selectedSampleVideoUrl, setSelectedSampleVideoUrl] = useState(null);
  const [isSampleModalOpen, setIsSampleModalOpen] = useState(false);


  const openModal = (videoUrl) => {
    setSelectedVideoUrl(videoUrl);
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
    navigate("/dashboard");
    setSelectedVideoUrl(null);
  };

  const closedSampleVideoModal = () => {
    setIsSampleModalOpen(false);
    setSelectedSampleVideoUrl(null);
  };

  const channelTypeOptions = [
    "Entertainment",
    "Education",
    "News",
    "Lifestyle",
    "Technology",
  ];
  const imageGenToolOptions = ["DALL-E", "Midjourney", "Stable Diffusion"];
  const voiceModelOptions = ["Default", "Neural TTS", "Custom Voice"];

  const handleAdvancedChange = (e) => {
    const { name, value } = e.target;
    setAdvancedData({ ...advancedData, [name]: value });
  };

  const toggleAdvanced = () => {
    setShowAdvanced(!showAdvanced);
  };
  const renderAdvancedForm = () => {
    return (
      <div className="mt-6 p-6 bg-white rounded-lg shadow-md">
        <h2 className="text-2xl font-semibold mb-4 text-indigo-700">
          Advanced Options
        </h2>
        <div className="space-y-4">
          <div>
            <label className="block text-sm font-medium text-gray-700">
              Channel Type
            </label>
            <select
              name="channelType"
              value={advancedData.channelType}
              onChange={(e) => {
                handleAdvancedChange(e);
                if (ChannelPrompts[e.target.value]) {
                  setAdvancedData((prevData) => ({
                    ...prevData,
                    storyPrompt: ChannelPrompts[e.target.value].storyPrompt,
                    imageGenPrompt:
                      ChannelPrompts[e.target.value].ImageGenPrompt,
                  }));
                }
              }}
              className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
            >
              <option value="">Select Channel Type</option>
              {Object.keys(ChannelPrompts).map((option) => (
                <option key={option} value={option}>
                  {option}
                </option>
              ))}
            </select>
          </div>
          <div>
            <label className="block text-sm font-medium text-gray-700">
              Story Prompt
            </label>
            <textarea
              name="storyPrompt"
              value={advancedData.storyPrompt}
              onChange={handleAdvancedChange}
              rows="3"
              className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
            ></textarea>
          </div>
          <div>
            <label className="block text-sm font-medium text-gray-700">
              Image Generation Prompt
            </label>
            <textarea
              name="imageGenPrompt"
              value={advancedData.imageGenPrompt}
              onChange={handleAdvancedChange}
              rows="3"
              className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
            ></textarea>
          </div>
          <div>
            <label className="block text-sm font-medium text-gray-700">
              Image Generation Tool
            </label>
            <select
              name="imageGenTool"
              value={advancedData.imageGenTool}
              onChange={handleAdvancedChange}
              className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
            >
              <option value="">Select Image Generation Tool</option>
              {imageGenToolOptions.map((option) => (
                <option key={option} value={option}>
                  {option}
                </option>
              ))}
            </select>
          </div>
          <div>
            <label className="block text-sm font-medium text-gray-700">
              Voice Model
            </label>
            <select
              name="voiceModel"
              value={advancedData.voiceModel}
              onChange={handleAdvancedChange}
              className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
            >
              <option value="">Select Voice Model</option>
              {voiceModelOptions.map((option) => (
                <option key={option} value={option}>
                  {option}
                </option>
              ))}
            </select>
          </div>
          <div>
            <label className="block text-sm font-medium text-gray-700">
              Image Slide Count
            </label>
            <input
              type="number"
              name="imageSlideCount"
              value={advancedData.imageSlideCount}
              onChange={handleAdvancedChange}
              className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
            />
          </div>
          <div>
            <label className="block text-sm font-medium text-gray-700">
              Language
            </label>
            <select
              name="language"
              value={advancedData.language || "English"}
              onChange={handleAdvancedChange}
              className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
            >
              <option value="English">English</option>
              <option value="Spanish">Spanish</option>
              <option value="French">French</option>
              <option value="German">German</option>
              <option value="Chinese">Chinese</option>
              <option value="Japanese">Japanese</option>
            </select>
          </div>
          <div>
            <label className="block text-sm font-medium text-gray-700">
              Where to Post
            </label>
            <select
              name="whereToPost"
              value={advancedData.whereToPost || "email"}
              onChange={handleAdvancedChange}
              className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
            >
              <option value="email">Email</option>
              <option value="youtube">YouTube</option>
              <option value="tiktok">TikTok</option>
              <option value="instagram">Instagram</option>
              <option value="facebook">Facebook</option>
            </select>
          </div>
        </div>
        <button
          onClick={handleCreateSeries}
          className="mt-6 flex items-center px-6 py-3 bg-green-600 text-white font-semibold rounded-full hover:bg-green-700 transition duration-300"
        >
          Generate Video <Check className="ml-2" />
        </button>
      </div>
    );
  };

  const steps = [
    {
      name: "topic",
      icon: PlayCircle,
      label: "Video topic",
      options: contentOptions,
    },
    // {
    //   name: 'language',
    //   icon: Globe,
    //   label: 'Language',
    //   options: languageOptions
    // },
    {
      name: "narrator",
      icon: Mic,
      label: "Narrator voice",
      options: narrationOptions,
    },
  ];

  // const handleChange = (value) => {
  //   setFormData({ ...formData, [steps[step - 1].name]: value });
  // };

  const handleChange = (value) => {
    const selectedOption = contentOptions.find(option => option.name === value);

    if (selectedOption) {
      setSelectedSampleVideoUrl(selectedOption.sampleVideoUrl); // Set the sample video URL
      setIsSampleModalOpen(true); // Open the modal when a valid option is selected
    } else {
      setSelectedSampleVideoUrl(null); // Reset if not a topic
      setIsSampleModalOpen(false); // Close the modal if no valid option
    }

    setFormData({ ...formData, [steps[step - 1].name]: value });
  };



  const nextStep = () => setStep(step < steps.length ? step + 1 : step);
  const prevStep = () => setStep(step > 1 ? step - 1 : step);

  const handleSubmit = async () => {
    const allData = {
      ...formData,
      ...advancedData,
      language: advancedData.language || formData.language,
      whereToPost: advancedData.whereToPost || formData.destination,
    };

    // Extract narrator ID from narrationOptions
    const selectedNarrator = narrationOptions.find(
      (narrator) => narrator.name === allData.narrator
    );

    if (selectedNarrator) {
      allData.narrator = selectedNarrator.id; // Replace name with ID
    }


    const executeSeriesCreation = async () => {
      if (!allData.topic || !allData.narrator) {
        // if (!allData.language || !allData.channelType || !allData.storyPrompt || !allData.imageGenPrompt || !allData.imageGenTool || !allData.voiceModel || !allData.imageSlideCount || !allData.whereToPost) {
        alert("Please fill in all required fields.");
        return;
      }

      const user = JSON.parse(localStorage.getItem("user"));
      return;
      // (await waitForSeconds(5));
      const data = {
        userEmail: user?.email,
        ...allData,
      };
      try {
        setLoading(true);

        const response = await axios.post(
          `${serverbaseURL}channels/series`,
          data
        );
        const resData = await response.data;
        alert(`${resData.message}`);
        localStorage.removeItem("formData");
        navigate("/dashboard");
      } catch (error) {
        console.error("Error sending video generation request:", error);
        alert(`${error.message}`);
      } finally {
        setLoading(false);
      }
    };
    if (!user) {
      localStorage.setItem("formData", JSON.stringify(formData));
      // setPostLoginCallback(() => executeSeriesCreation);
      try {
        const result = await googleSignIn();
        const loggedUser = result.user;
        localStorage.setItem("user", JSON.stringify(loggedUser));
        setUser(loggedUser);
        // executeSeriesCreation();
      } catch (error) {
        console.error("Error during login:", error);
      }
      return;
    } else {
      executeSeriesCreation();
    }

    try {
      const response = await axios.post(
        `${serverbaseURL}generate_video`,
        allData
      );
    } catch (error) {
      console.error("Error sending video generation request:", error);
    }
    navigate("/dashboard");
  };

  const handleCreateSeries = async () => {
    const executeSeriesCreation = async () => {
      const allData = {
        ...formData,
        ...advancedData,
        language: advancedData.language || formData.language,
        whereToPost: advancedData.whereToPost || formData.destination,
      };

      // Extract narrator ID from narrationOptions
      const selectedNarrator = narrationOptions.find(
        (narrator) => narrator.name === allData.narrator
      );

      if (selectedNarrator) {
        allData.narrator = selectedNarrator.id; // Replace name with ID
      }
      const data = {
        userEmail: user?.email,
        ...allData,
      };
      try {
        if (data.topic && data.narrator) {
          setIsLoading(true)
          const response = await axios.post(`${serverbaseURL}create_single_video`, data);
          const resData = await response.data;

          setSelectedVideoUrl(resData.videoUrl);
          // alert(`${resData.message}`);
          openModal(resData.videoUrl)

        }
      } catch (error) {
        console.error("Error sending video generation request:", error);
      } finally {
        setIsLoading(false)
      }
    };

    if (!user) {
      alert("You have to login to create a series.");
      localStorage.setItem("formData", JSON.stringify(formData));
      setPostLoginCallback(() => executeSeriesCreation); // Set callback for post-login
      navigate("/login");
      return;
    }

    executeSeriesCreation();
  };
  return (

    <motion.div
      className="max-w-4xl  mx-auto py-8 px-12 rounded-3xl  bg-gradient-to-b from-[#ebefff] via-[#805af5] to-[#d9e0fd] shadow-lg my-12 transition-all duration-300 ease-linear  hover:shadow-2xl"

      initial={{ opacity: 0, x: 1000, scale: 0.95 }} // Starts off-screen to the left with slight scale down
      animate={{ opacity: 1, x: 0, scale: 1 }} // Animates to full opacity and position with scale up
      transition={{
        duration: 0.4, // Duration for smooth transition
        ease: 'easeInOut', // Smooth easing function for smooth and natural animation
        delay: 0.1, // A slight delay for a more fluid effect
      }}>


      <h1 className="text-4xl font-bold text-center mb-10 text-black">
        Create Your Video
      </h1>

      <div className="mb-8">
        <ProgressBar currentStep={step} totalSteps={steps.length} />
      </div>

      {/* <button
        onClick={toggleAdvanced}
        className="mb-4 px-4 py-2 bg-indigo-600 text-white font-semibold rounded-full hover:bg-indigo-700 transition duration-300"
      >
        {showAdvanced ? "Basic" : "Advanced"}
      </button> */}

      {showAdvanced ? (
        renderAdvancedForm()
      ) : (
        <div className=" relative  border-2 border-[#805af5]  bg-[#f8f8ff] rounded-lg shadow-lg p-11">
          <StepContent
            step={steps[step - 1]}
            value={formData[steps[step - 1].name]}
            onChange={handleChange}
          />
        </div>
      )}

      {!showAdvanced && (
        <motion.div
          className="flex justify-between mt-8"
          initial={{ opacity: 0, x: -30 }}
          animate={{ opacity: 1, x: 0 }}
          transition={{ duration: 0.5 }}
        >
          <motion.button
            onClick={prevStep}
            className={`flex items-center px-6 py-3 rounded-full text-indigo-600 font-semibold ${step === 1 ? "invisible" : ""}`}
            whileHover={{ scale: 1.05 }}
            transition={{ duration: 0.3 }}
          >
            <ChevronLeft className="mr-2" /> Previous
          </motion.button>

          {step < steps.length ? (
            <motion.button
              onClick={nextStep}
              className="flex items-center px-6 py-3 bg-gradient-to-r from-[#6a48f2] to-[#cd99ff] text-white font-semibold rounded-full hover:bg-indigo-700 transition duration-300"
              whileHover={{ scale: 1.05 }}
              transition={{ duration: 0.3 }}
            >
              Next <ChevronRight className="ml-2" />
            </motion.button>
          ) : (
            <motion.button
              onClick={handleCreateSeries}
              className="flex items-center px-6 py-3 bg-gradient-to-r from-[#805af5] to-[#cd99ff] text-white font-semibold rounded-full hover:bg-green-700 transition duration-300"
              whileHover={{ scale: 1.05 }}
              transition={{ duration: 0.3 }}
            >
              {isLoading ? (
                <div className="flex items-center">
                  <CgSpinner className="animate-spin mr-2" />
                  About 30 seconds
                </div>
              ) : (
                "Create Video"
              )}
            </motion.button>
          )}
        </motion.div>
      )}
      {/* Modal */}
      <Modal
        isOpen={isModalOpen}
        onRequestClose={closeModal}
        contentLabel="Video Modal"
        className="modal"
        overlayClassName="modal-overlay z-40"
      >
        <div className="modal-content">
          <button onClick={closeModal} className="close-button mb-5">Close</button>
          {selectedVideoUrl && (
            <iframe
              width="100%"
              height="400px"
              src={`${selectedVideoUrl}?autoplay=1`}
              title="Video"
              frameBorder="0"
              allow="autoplay; fullscreen"
              allowFullScreen
            ></iframe>
          )}
        </div>
      </Modal>

      {/* Modal */}
      <Modal
        isOpen={isSampleModalOpen}
        onRequestClose={closedSampleVideoModal}
        contentLabel=" Sample Video Modal"
        className="modal"
        overlayClassName="modal-overlay"
      >
        <div className="modal-content">
          <button onClick={closedSampleVideoModal} className="close-button mb-5">Close</button>
          {selectedSampleVideoUrl && (
            <iframe
              width="100%"
              height="400px"
              src={`${selectedSampleVideoUrl}?autoplay=1`}
              title=" Sample Video"
              frameBorder="0"
              allow="autoplay; fullscreen"
              allowFullScreen
            ></iframe>
          )}
        </div>
      </Modal>
    </motion.div>
  );
};

const ProgressBar = ({ currentStep, totalSteps }) => (
  <div className="w-full bg-indigo-100 rounded-full h-2.5">
    <div
      className="bg-indigo-600 h-2.5 rounded-full transition-all duration-500 ease-out"
      style={{ width: `${(currentStep / totalSteps) * 100}%` }}
    ></div>
  </div>
);

const StepContent = ({ step, value, onChange }) => (
  <div className="text-center">
    <step.icon className="w-16 h-16 mx-auto text-indigo-600 mb-4" />
    <h2 className="text-2xl font-semibold mb-6">{step.label}</h2>
    <div className="flex flex-wrap justify-center gap-4">
      {step.options.map((option) => {
        // Determine if the option is an object or a string
        const optionValue = typeof option === "string" ? option : option.name;
        return (
          <ToggleButton
            key={optionValue}
            option={option}
            isSelected={value === optionValue}
            onClick={() => onChange(optionValue)}
          />
        );
      })}
    </div>
  </div>
);

const ToggleButton = ({ option, isSelected, onClick }) => {
  const optionLabel = typeof option === "string" ? option : option.name;
  const optionIcon =
    typeof option === "object" && option.icon ? option.icon : null;
  const optionAudio =
    typeof option === "object" && option.audio ? option.audio : null;

  const handleClick = () => {
    onClick();
    if (optionAudio) {
      // Stop any currently playing audio
      if (window.currentAudio) {
        window.currentAudio.pause();
        window.currentAudio.currentTime = 0;
      }
      const audio = new Audio(optionAudio);
      audio.play();
      // Store the current audio globally
      window.currentAudio = audio;
    }
  };

  return (
    <button
      onClick={handleClick}
      className={`px-4 py-2 rounded-full  text-[16px] font-[500] cursor-pointer font-serif transition-all duration-500 ease-in-out  ${isSelected
        ? "bg-purple-300 text-black shadow-lg scale-105"
        : "bg-gray-200 text-gray-800 hover:bg-purple-200 hover:border-indigo-200"
        } flex items-center`}
    >
      {optionIcon && <span className="mr-2">{optionIcon}</span>}
      {optionLabel}
    </button>
  );
};
export default VideoCreationWizard;