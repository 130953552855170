import { useContext } from 'react';
import { FaRocket, FaVideo, FaRobot } from 'react-icons/fa';
import { useNavigate } from 'react-router-dom';
import { AuthContext } from '../provider/AuthProvider';
import { motion } from "framer-motion";




const Hero = () => {
  const navigate = useNavigate();
  const { user } = useContext(AuthContext)
  const handleButtonClick = () => {
    if (!user) {
      navigate('/login');
    }
    else {
      navigate('/dashboard');
    }
  };
  return (

    <div className=' max-w-6xl mx-auto mb-10 px-5 pt-16 md:pt-24 pb-10 md:pb-14'>
      <div className='flex flex-col justify-center items-center'>
        <h1 className=" text-4xl sm:text-5xl lg:text-7xl text-center text-black font-bold leading-tight sm:leading-snug mb-6 sm:mb-8 font:[74px]">
          Faceless Videos on Auto-Pilot: <br />
          <motion.span
            className="block sm:inline lg:text-6xl"
            initial={{ backgroundPosition: "0% 50%" }}
            animate={{ backgroundPosition: "200% 50%" }} // Extended movement range
            transition={{ duration: 4, repeat: Infinity, repeatType: "reverse" }}
            style={{
              backgroundImage:
                "linear-gradient(90deg, #6a48f2, #cd99ff, #6a48f2)", // Loopable gradient
              backgroundSize: "200%", // Ensures gradient covers enough width for animation
              backgroundClip: "text",
              WebkitBackgroundClip: "text",
              color: "transparent",
            }}
          >
            Effortless Creation
          </motion.span>
        </h1>
        <p className=' font-custom  font-[22px]   sm:text-2xl text-[#55595c] text-center my-7 sm:my-10 max-w-3xl'>
          Our powerful AI video creation platform allows you to fully automate a faceless channel. Unleash your creativity without showing your face!
        </p>
        <div className='flex flex-wrap justify-center gap-6 sm:gap-8 my-10 sm:my-12'>
          <FeatureCard icon={<FaRocket />} title="Quick & Easy" description="Create videos in minutes, not hours" />
          <FeatureCard icon={<FaVideo />} title="Professional Quality" description="High-quality videos without expensive equipment" />
          <FeatureCard icon={<FaRobot />} title="AI-Powered" description="Leverage cutting-edge AI for content creation" />
        </div>
        <button
          className='bg-gradient-to-r from-[#805af5] to-[#cd99ff] text-white font-medium text-base sm:text-lg py-3 sm:py-4 px-6 sm:px-8 rounded-lg hover:shadow-lg  transition duration-400 ease-in-out w-auto leading-[50px] tracking-[0.5px] mt-2 border-0 text-center inline-block'
          onClick={handleButtonClick}
        >
          Start Creating Now - It's Free!
        </button>
        <p className='text-sm text-gray-500 pt-4 sm:pt-5'>No credit card required</p>
      </div>
    </div>
  )
}

const FeatureCard = ({ icon, title, description }) => (
  <div className='flex flex-col items-center bg-gradient-to-r from-[#e6f7ff] to-[#c0d9ff] p-6 sm:p-8 rounded-lg shadow-lg hover:shadow-xl transition duration-300 ease-in-out w-full sm:w-64 transform hover:scale-105'>
    <div className='text-3xl sm:text-4xl mb-3 sm:mb-4 text-blue-600'>{icon}</div>
    <h3 className='text-lg sm:text-xl font-semibold text-transparent bg-clip-text bg-gradient-to-r from-[#6a48f2] to-[#cd99ff] mb-3 text-center'>
      {title}
    </h3>
    <p className='text-center text-gray-600 text-sm sm:text-base  '>
      {description}
    </p>
  </div>

)

export default Hero
