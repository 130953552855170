import React, { useState, useEffect } from 'react';
import { Sliders, ChevronDown } from 'lucide-react';
import TemplateCard from '../../components/MarketPlace/TemplateCard';
import { serverbaseURL } from "../../constant/index";
import axios from 'axios';

const SORT_OPTIONS = ['Popular', 'Newest', 'Price: Low to High', 'Price: High to Low', 'Rating'];

export default function TemplatesListing() {
  const [selectedCategory, setSelectedCategory] = useState('All');
  const [sortBy, setSortBy] = useState('Popular');
  const [templates, setTemplates] = useState([]);
  const [searchText, setSearchText] = useState('');

  // Fetch templates from the backend
  useEffect(() => {
    const fetchTemplates = async () => {
      try {
        const response = await axios.get(`${serverbaseURL}get-template`);
        setTemplates(response.data.templates);
      } catch (error) {
        console.error('Error fetching templates:', error);
      }
    };

    fetchTemplates();
  }, []);
  // Extract unique categories from templates
  const categories = ['All', ...new Set(templates.map(template => template.category))];

  // // Filter templates based on the selected category
  // const filteredTemplates = selectedCategory === 'All'
  //   ? templates
  //   : templates.filter(template => template.category === selectedCategory);

  // Filter templates based on the selected category and search text
  const filteredTemplates = templates.filter((template) => {
    const matchesCategory = selectedCategory === 'All' || template.category === selectedCategory;
    const matchesSearch =
      template.title.toLowerCase().includes(searchText.toLowerCase()) ||
      template.category.toLowerCase().includes(searchText.toLowerCase());
    return matchesCategory && matchesSearch;
  });

  // Sort filtered templates
  const sortedTemplates = filteredTemplates.sort((a, b) => {
    switch (sortBy) {
      case 'Price: Low to High':
        return a.price - b.price;
      case 'Price: High to Low':
        return b.price - a.price;
      case 'Rating':
        return b.averageRating - a.averageRating; // Assuming averageRating is the correct field for rating
      case 'Newest':
        return new Date(b.createdAt) - new Date(a.createdAt);
      default: // 'Popular' or any other default
        return 0;
    }
  });
  const handleSearchChange = (e) => {
    setSearchText(e.target.value);
  };

  return (
    <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-8">
      <div className="flex flex-col sm:flex-row justify-between items-center mb-8">
        <div className="flex space-x-2 overflow-x-auto pb-2 sm:pb-0">
          {categories.map((category) => (
            <button
              key={category}
              onClick={() => setSelectedCategory(category)}
              className={`px-4 py-2 rounded-full text-sm font-medium ${selectedCategory === category
                ? 'bg-indigo-600 text-white'
                : 'bg-gray-100 text-gray-800 hover:bg-gray-200'
                }`}
            >
              {category}
            </button>
          ))}
        </div>

        <div className="flex items-center space-x-4 mt-4 sm:mt-0">
          {/* <button className="flex items-center space-x-2 px-4 py-2 border border-gray-300 rounded-lg text-sm font-medium text-gray-700 hover:bg-gray-50">
            <Sliders className="h-4 w-4" />
            <span>Filters</span>
          </button> */}
          <div className="relative w-1/2 sm:w-auto">
            <input
              value={searchText}
              onChange={handleSearchChange}
              type="text"
              placeholder="Search templates..."
              className="block w-full pl-10 pr-3 py-2 border border-gray-300 rounded-md leading-5 bg-white placeholder-gray-500 focus:outline-none focus:placeholder-gray-400 focus:ring-2 focus:ring-indigo-500"
            />
            <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
              <Sliders className="h-5 w-5 text-gray-400" />
            </div>
          </div>

          <div className="relative">
            <select
              value={sortBy}
              onChange={(e) => setSortBy(e.target.value)}
              className="appearance-none bg-white border border-gray-300 rounded-lg py-2 pl-4 pr-10 text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500"
            >
              {SORT_OPTIONS.map((option) => (
                <option key={option} value={option}>
                  {option}
                </option>
              ))}
            </select>
            <ChevronDown className="absolute right-3 top-1/2 transform -translate-y-1/2 h-4 w-4 text-gray-400" />
          </div>
        </div>
      </div>

      <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6">
        {sortedTemplates.map((template) => (
          <TemplateCard
            key={template._id}
            id={template._id}
            title={template.title}
            price={template.price}
            rating={template.averageRating}
            category={template.category}
            creator={{ name: template.creatorName || template.creator }}
            thumbnail={template.imageUrl} // Use the imageUrl from the backend response
            sellerId={template.sellerId}
          />
        ))}
      </div>
    </div>
  );
}
