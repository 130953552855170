import React, { createContext, useContext, useState } from 'react';

// Create the Cart Context
const TemplateCartContext = createContext();

// Custom hook to use the Cart Context
export const useCart = () => useContext(TemplateCartContext);

// Cart Provider component
export const CartProvider = ({ children }) => {
  const [cartItems, setCartItems] = useState(() => {
    // Retrieve cart items from localStorage (if any)
    const savedItems = localStorage.getItem('cartItems');
    return savedItems ? JSON.parse(savedItems) : [];
  });

  // Update localStorage whenever cartItems changes
  React.useEffect(() => {
    localStorage.setItem('cartItems', JSON.stringify(cartItems));
  }, [cartItems]);

  const addToCart = (item) => {
    setCartItems((prevItems) => {
      const updatedItems = [...prevItems, item];
      return updatedItems;
    });
  };

  const removeFromCart = (id) => {
    setCartItems((prevItems) => prevItems.filter(item => item.id !== id));
  };

  return (
    <TemplateCartContext.Provider value={{ cartItems, addToCart, removeFromCart }}>
      {children}
    </TemplateCartContext.Provider>
  );
};
