import React, { useState, useContext } from "react";
import { useNavigate } from "react-router-dom";
import axios from 'axios';
import { Loader2 } from "lucide-react";
import {
  serverbaseURL,
} from "../constant/index.jsx";
import { AuthContext } from "../provider/AuthProvider";
import { motion } from 'framer-motion';


//todo: cearte a new route /template_video_generation and send the themeTempate as body there
// new function is generatePrompts whuch takes theme and template as input and returns story and prompts
//const ImagePrompts = prompts[1]; await processImagePrompts(ImagePrompts); pass the image prompts array to this function.
//process imagePrompts will take each image prompt and send it to generateImage (which i have to change)
//then the same text to voice and createVideo function from before.

const Templates = () => {

  const hardcodedTemplates = [
    {
      id: 1,
      name: "The Great Gummy Bear Showdown",
      description: "Dwayne The Rock Johnson and Arnold Schwarzenegger face a hilarious battle with giant gummy bears that come to life, causing playful chaos in a sunny suburban playground.",
      type: "Custom",
      image: "https://res.cloudinary.com/dyzcpcibf/image/upload/v1731090461/generated_test_images/trwf48fpuarcluodpybt.png",
      previewVideo: "https://www.w3schools.com/html/mov_bbb.mp4",
      themeTemplate: `While taking a stroll in a quiet suburban neighborhood, The Rock and Arnold notice an unusual commotion near a playground. As they approach, they are suddenly confronted by a horde of giant, colorful gummy bears that have come to life and are causing playful chaos. The Rock lifts a massive teddy bear to clear a path, while Jackie skillfully evades a flying gummy bear. Just when they think they have got things under control, a massive red gummy bear wielding a toy hammer appears, forcing them to join forces in an epic battle to restore peace to the neighborhood.

        I want you create a another story similar to this with different characters and divide that story into 8 parts and then create text prompt for each scene in details to generate images. Treat each prompt as fresh prompt while keeping the clothes and background reference in each prompt same. strictly, In each prompt out of 8, mention characters clothes in text and color in text prompt so it is not missed in any prompt  and background.
        The vibrant scene is set against a sunny backdrop featuring a yellow and red playground structure with slides, lush green trees, and an American flag fluttering in the distance. The lighting is bright and cheerful, casting soft shadows in the sandbox, creating a heartwarming atmosphere.
        As the story unfolds, the playful spirit continues in subsequent scenes. In one instance, Arnold's toddler body is juxtaposed with his hyper-realistic facial features, showcasing an exaggerated intense expression while shouting with clenched fists in a bustling playground. The contrasting styles enhance the absurdity of the moment.
        Further along, Dwayne's shocked face becomes a focal point in a vibrant playground filled with children, as he reacts to a surreal swarm of menacing gummy bears that appear to chase him, setting a humorous yet tension-filled scene.
        The climax builds with Dwayne engaged in a comical martial arts battle against a giant red gummy bear, showcasing his determination. In a humorous twist, he grapples with a giant turquoise gummy bear in a suburban front yard, surrounded by an audience of smaller gummy bears watching intently, their expressions adding to the whimsical nature of the unfolding drama.
        The narrative weaves through absurd and surreal moments, emphasizing the blend of humor and charm in the delightful playground setting, creating a story that captures the imagination and tickles the funny bone.

        The story will be used for text to speech so Keep the story short for a 30 to 45 second Shorts Video.
        Format the response as an array of 2 arrays,the first one has story and second array has 8 objects having 'prompt' as key containing the respective image prompt.
        The response should be strictly formatted as [ [{ "story": "..." }], [{ "prompt": "..." },...]] without any extra text or numbering.`
    },
    {
      id: 2,
      name: "Comical Artstyle Clash of Superheros",
      description: "Supergirl leads to a story of strength and friendship, as Supergirl trains to avenge her mentor's fall and ultimately takes down the mighty Thanos.",
      type: "Custom",
      image: "https://res.cloudinary.com/dyzcpcibf/image/upload/v1730024124/generated_test_images/dl8zt4ijyryqdxayevde.png",
      previewVideo: "https://www.w3schools.com/html/mov_bbb.mp4",
      themeTemplate: `I want you create a another story similar to this with different superhero characters  from marvel and DC universe and divide that story into 20 scenes and then create text prompt for each scene in details to generate images in leonardo. Treat each prompt as fresh prompt while keeping the clothes and background reference in each prompt same. strictly, In each prompt out of 20, mention characters clothes in text and color in text prompt so it is not missed in any prompt  and background. everytime when character name is mention also mention their clothing in the prompt .
            For reference this is the story -In a world where superheroes walk among mortals, an unlikely friendship blossoms between She-Hulk and a young, aspiring Supergirl. The green-skinned hero discovers the determined girl awkwardly throwing punches at a heavy bag in a modest boxing gym. Recognizing potential, She-Hulk takes the girl under her wing, becoming more than just a combat trainer; she becomes a life mentor. Between proper fighting techniques, playful sparring sessions, and lectures on the importance of eating vegetables (much to the young hero's pouty dismay), they form a bond thats equal parts strength and humor.
            Their peaceful training routine is shattered one day by the arrival of Thanos, the Mad Titan. Though She-Hulk stands her ground, she is ultimately defeated by his cosmic might. she hulk is now in hospital. Young Supergirl, devastated by her mentor's fall, is fueled by an unstoppable rage and determination. She throws herself into intense training, even embarking on a perilous journey to face down a mighty dragon, pushing her abilities and courage to new limits. 
            Finally, prepared and empowered, Supergirl returns to confront Thanos. In a battle that transcends from the boxing ring to the stars, she draws upon every lesson She-Hulk taught her. With a powerful final blow, she defeats Thanos, disintegrating him into particles. As the dust settles, Supergirl stands proudly beside She-Hulk, both heroes battered but victorious. Their story becomes a testament to the strength found in unlikely friendships, the resilience fostered through mentorship, and the power unlocked when someone truly believes in you.
            
            The story will be used for text to speech so Keep the story short for a 30 to 45 second Shorts Video.
            Format the response as an array of 2 arrays,the first one has story and second array has objects having 'prompt' as key containing the respective image prompt.
            The response should be strictly formatted as [ [{ "story": "..." }], [{ "prompt": "..." },...]] without any extra text or numbering.`
    },
    {
      id: 3,
      name: "The Slimey Adventure of Mr. Bear and Family",
      description: "A playful scene of fatherhood as a dad bear and his mischievous bond over a slimey mess, finding humor and warmth in a cozy, slime-filled adventure",
      type: "Custom",
      image: "https://res.cloudinary.com/dyzcpcibf/image/upload/v1731091017/generated_test_images/ldcg7sky14bms48bdcyl.jpg",
      previewVideo: "https://www.w3schools.com/html/mov_bbb.mp4",
      themeTemplate: `I want you create a another story similar to this with different animal characters and divide that story into 9 scenes and then create text prompt for each scene in details to generate images in leonardo. 
            Treat each prompt as fresh prompt while keeping the clothes and background reference in each prompt same. strictly, In each prompt out of 9, write the animal characters name and body color, clothes and body color and the goo/slime color in each prompt so it is not missed in any prompt  and background. 
            For reference this is the story - In a cozy, softly lit living room, an tabby cat dad finds himself on a rather messy adventure with his playful kitten. It all begins as he tries to put a diaper on the squirmy little kitten, who suddenly spots a puddle of bright green slime nearby. With a mischievous glint in its eye, the kitten wriggles free and leaps off the changing table, landing right in the goo with a gleeful giggle. As the kitten rolls around, covering itself in slime, the dad cat sighs, torn between amusement and mild exasperation. Determined, he picks up the slippery kitten, trying once more to secure the diaper. But the kitten, full of playful energy, squirms and manages to flick a bit of slime onto the dads face, catching him off guard. Finally, both cats end up sitting on the floor, both covered in gooey green slime, laughing together. The kitten nuzzles up to the dad, who chuckles and gives it a gentle pat, sharing a lighthearted, messy moment that fills the room with warmth and laughter. 
            This cozy scene captures the funny, chaotic charm of fatherhood and the unbreakable bond between the cat dad and his mischievous little kitten.

            The story will be used for text to speech so Keep the story short for a 30 to 45 second Shorts Video.
            Format the response as an array of 2 arrays,the first one has story and second array has objects having 'prompt' as key containing the respective image prompt.
            The response should be strictly formatted as [ [{ "story": "Video Narration" }], [{ "prompt": "Image Prompt" },...]] without any extra text or numbering.`
    },
    {
      id: 4,
      name: "Quest for Inner Peace",
      description: "A troubled king learns the power of silence and self-reflection under the guidance of a wise monk, discovering inner peace in a quiet forest.",
      type: "Custom",
      image: "https://res.cloudinary.com/dyzcpcibf/image/upload/v1731091185/generated_test_images/n8wiesdggt7ojatyfgj0.jpg",
      previewVideo: "https://www.w3schools.com/html/mov_bbb.mp4",
      themeTemplate: `Create a video script as paragraph  similar to this one but for a 60 sec video - this script for 11 min video-
            **Speaker**: Gautama Buddha always used to tell his students that our words can be very powerful. He said that our tongue, which we use to speak, is like the sharpest knife in the world. He also said that the most dangerous weapon in the world is our words. Even though they don’t physically hurt people, our words can hurt people emotionally. Our words can be a source of strength, but they can also be a weakness if we don’t know when, where, and how much to speak. When we speak kindly and wisely at the right moments, it can lead to success, but if we speak unkindly or at the wrong times, it can make our life very difficult.
            So, friends, today Ill share a story about the importance of staying silent and how it has the power to change your life. This story will help you understand how silence can be powerful.

            **Story Narration**:
            Once upon a time in India, there was a famous Buddhist monk who lived deep in the quiet forest, far from the noise of the world. He was very meditative and well-known throughout India. One day, a king came to him and said, “Respected monk, I always feel tired, frustrated, and upset. My mind is very restless. Even though I have everything—wealth, fame, and all the luxuries one could desire—there is still no inner peace within me. Oh monk, please help me to get rid of this restless mind.”
            The monk replied, “You already hold the answer within you, but you have never discovered it. I want to do an experiment. Would you like to do that experiment? After this experiment, there will be no unrest and worry in your life.”
            The king, very upset but hopeful, agreed to the monk's plan and asked what to do.
            The monk calmly said, “For the next 15 days, you will stay in my monastery and live like a normal person. For these 15 days, you will sit alone in silence and speak as little as possible.” The king agreed to this.
            On the first day, he noticed that his mind was more restless than usual. Many questions and thoughts kept arising, and he felt uneasy living a simple life like everyone else. He wanted to talk but resisted the urge and remained silent. As the night went on, he began to feel an unexpected sense of calm and happiness. This puzzled him because he couldnt understand why his restlessness was fading.
            The next day, he continued to spend time in solitude, watching nature—flowers, plants, and birds in the sky. For the first time, he truly appreciated the beauty of nature surrounding him. He could observe and connect with the natural world around him.
            Ten days went by, and all the inner restlessness and chaos in his mind gradually vanished. By the 12th day, he found himself entering a deep meditative state, with his mind becoming free from worry. After spending the last three days in deep meditation, he approached the Buddhist monk and said, “Respected monk, I have discovered why my mind was so troubled. I used to talk too much and spend a lot of energy on people, often thinking negatively about them. This excessive talking and negative thinking wasted my time and disturbed my mind. These 15 days were a transformative experience. Now, I feel like Im truly living life to its fullest, and I realized that before this, I was living it the wrong way.”
            The monk nodded and explained that many people in the world talk too much and use speech to hurt others. Excessive talking is still causing problems in peoples lives. He shared a story about a student who once asked Gautama Buddha why he was often silent. Buddha replied, “I stay silent to dive deep within myself. Silence is the first step to truly knowing yourself. Without silence, self-discovery is impossible.”
            The monk advised, “Only speak when necessary. Most of our talking—about 90%—is harmful. If people didnt talk too much, there would be fewer fights, less restlessness, and fewer arguments. Silence can bring peace even in a crowded place. Look inward; you are the ultimate ruler of your inner world. Thats where life begins and where happiness is born.”

            **Reflection and Closing**:
            Friends, you might have noticed that talking too much doesnt bring peace to the mind; instead, it makes it hard to control our thoughts and stay focused. People who talk excessively often struggle to concentrate and give their full attention to their goals. Those who talk less, consider their words, and stand by them earn respect from others.
            The monk's message to the king and to all of us is to develop the skill of speaking wisely. Observing great individuals, we find they use few words carefully, contributing to their success. In contrast, excessive talking, dishonesty, and spreading negativity harm relationships and reputations.
            Ancient texts emphasize that our words reflect our beliefs and values. Practicing silence helps us become more patient, self-aware, and meditative. My friends, I suggest you spend at least 20 minutes alone in solitude daily. It can bring so much positive change to your life.
        
            -- then divide that video script in scenes of still images and then  give me Text prompt to generate images in leonardo for that script.
            Format the response as an array of 2 arrays,the first one has video story for 10 minute narration and second array has 10 objects each having 'prompt' as key containing the respective image prompt.
            The response should be strictly formatted as [ [{ "story": "Video Narration" }], [{ "prompt": "Image prompt" },...]] without any extra text or numbering.`
    },
    {
      id: 5,
      name: "A Boys Journey to Friendship",
      description: " A solitary boy at the playground finds strength within himself, even as his friend leaves him behind, ultimately learning valuable lessons about friendship and resilience.",
      type: "Custom",
      image: "https://res.cloudinary.com/dyzcpcibf/image/upload/v1731092006/generated_test_images/neywbgbrn9ao2w0nzeso.webp",
      previewVideo: "https://www.w3schools.com/html/mov_bbb.mp4",
      themeTemplate: `Create a video script as paragraph do not mention scenes so it can be converted to audio , similar to this script  create another script for a 60 sec video - this script for 5 min video-
          There was a boy who always played alone in the playground. He would come to the playground with his friend, but the friend always left him to play with the other children.

          One day, while he was sitting on the bench, a dog approached him and started playing with him. The boy smiled and became happy. Every day, he returned to the playground just to play with the dog.

          "You make me happy," he said to the dog.

          One day, while he was playing with the dog, his friend took a stick and threw it onto the road. The dog ran for the stick when suddenly it was hit by a car.

          "No!" shouted the boy. His friend and the other children started laughing. The boy ran to the dog and saw that it was injured. He cried and moved the dog out of the road.

          While he was sitting next to the dog, a man approached him. "I will help you," the man told him and took the dog.

          "Thank you," replied the boy. Together, the man and the boy took the dog to the vet. The vet looked at the dog and said, "The dog's leg is broken."

          "Will he survive?" asked the boy in fear.

          "He will, don't worry," answered the vet with a smile on his face. The man helped the boy take the dog home. The boy and his father built a house for the dog, gave him water, and took care of him every day.

          The next morning, the boy came to the playground with his friend again.

          "Where is your dog?" his friend asked.

          "The dog's leg is broken. He will need time to recover," replied the boy.

          His friend started laughing and said, "Then go play alone," and with a smile, he went to play with the other children.

          After some time, the dog recovered. The boy was very happy, and together with the dog, he came to the playground to play. He saw his friend on the playground, but the friend had an injured arm.

          The boy approached him and asked, "What happened to your arm?"

          "I fell, but it doesn't hurt," replied the friend.

          "I am sorry I threw the stick and hurt your dog," said the boy.

          He didnt say anything.

          "Do you want to play together?" the friend asked.

          The boy thought for a second and answered, "Yes."

          The boy and his friend started playing football. The dog was lying next to the field and watched the boys. The boy was winning, and at one point, his friend pushed him, causing the boy to fall and hurt his leg.

          "It hurts!" yelled the boy in tears. The dog came up to the boy and started barking at his friend. The friend started laughing and said, "I didn't want to play with you. I was just kidding; I wanted to hurt you," and left with a smile.

          Again, the boy could not move his leg. Then he heard a familiar voice: "I will help you." When he looked, he saw that it was the same man who had helped him with the dog.

          "Thank you very much," said the boy. The man took the boy to the hospital, leaving the dog in front of the hospital to wait. The doctor looked at the boy and asked, "How did you fall?"

          "I didn't fall; my friend pushed me," he replied.

          "Not all those we call friends are our friends," said the doctor.

          "Is my leg going to be okay?" asked the boy.

          "You are okay. Tomorrow everything will be fine," assured the doctor.

          The man helped the boy come home. While they were walking to the boy's house, the dog walked next to them. Then the dog saw his friend and started barking furiously, running toward him. The friend started to run away from the dog and then fell, hurting his arm.

          The friend cried in pain and shouted, "The dog hurt me! Help me!"

          The man whispered in the boy's ear, "Life always gives back what you do to others."

          A few days later, someone knocked on the door of the boy's house. The boy's father opened the door and saw a policeman.

          "Your boy's dog hurt another kid," the policeman said.

          The father was shocked. "We will have to take your dog away," said the policeman.

          "Why did your dog hurt your friend?" the father shouted angrily at the boy.

          The next day, the boy sat in his room and cried. "I miss my dog!" screamed the boy.

          After a couple of weeks, the boy came to the playground again. He sat alone on the bench and thought about his dog. At one point, a man appeared behind him.

          "Turn around," he said.

          The boy turned and saw a man holding his dog. The boy became very happy and hugged the man. "Thank you very much!" shouted the boy.

          The man then said, "Let's go for a walk together." While they were walking, the man said, "Your friend always hurts you, and friends don't do that."

          "I just don't want to be alone," the boy replied.

          "You are not alone anymore. Life rewarded you with a dog," said the man.

          The boy smiled and told the man, "The dog makes me very happy every day."

          The man smiled and said, "That is how real friendship should look like."

          After some time, the boy came to the playground every day to play with his dog. One day, he noticed his old friend sitting alone on a bench. He approached his friend and asked him, "Why are you sitting alone? Why don't you play with your other friends?"

          The old friend looked at him and sadly replied, "No one wants to have a fake friend." 

          -- then divide that video script in scenes of still images and then  give me Text prompt to generate images in leonardo for that script.
          Format the response as an array of 2 arrays,the first one has video story for 5 minute narration and second array has 10 objects each having 'prompt' as key containing the respective image prompt.
          The response should be strictly formatted as [ [{ "story": "Video Narration" }], [{ "prompt": "Image prompt" },...]] without any extra text or numbering.`
    },
    {
      id: 6,
      name: "Voyage to Proxima Centauri",
      description: " A starships century-long voyage to Proxima Centauri b, capturing cosmic wonders and the spirit of exploration.",
      type: "Custom",
      image: "https://res.cloudinary.com/dyzcpcibf/image/upload/v1730022057/generated_test_images/dtq5spoyapnyi3q2lnd1.png",
      previewVideo: "https://www.w3schools.com/html/mov_bbb.mp4",
      themeTemplate: `The story captures the epic journey of a nuclear fusion-powered spacecraft traveling for 100 years to reach Proxima Centauri b, the closest potentially habitable planet to Earth, 4.24 light years away. 
            The visuals should convey the vastness and mystery of interstellar space, depicting futuristic spacecraft design, advanced space travel technology, and the changing landscapes of deep space. 
            Include elements such as the glowing fusion reactor at the heart of the ship, vast celestial phenomena like nebulae and asteroid fields, and the evolving appearance of the distant Proxima Centauri star as the ship gets closer.
            Illustrate key moments like the ship launching from Earth's orbit, navigating through dense asteroid belts, witnessing awe-inspiring cosmic events such as supernovae, and finally, approaching the alien atmosphere of Proxima Centauri b. 
            The scenes should evoke a sense of wonder, ambition, and the pioneering spirit of space exploration, with dramatic lighting, rich contrasts, and detailed futuristic designs. 
            Highlight the transformation over time, showing the wear and age of the spacecraft as it ventures further from Earth, symbolizing the passage of a century in space.
            Create similar the narration story and the prompts based on this space exploration theme. 

            Format the response as an array of 2 arrays, the first one has video story for 30-45 seconds narration and second array has 6 objects each having 'prompt' as key containing the respective image prompt.
            The response should be strictly formatted as [ [{ "story": "Video Narration" }], [{ "prompt": "Image prompt" },...]] without any extra text or numbering.`
    },
  ];

  const [templates] = useState(hardcodedTemplates);
  const [selectedTemplate, setSelectedTemplate] = useState(null);
  const [showPreview, setShowPreview] = useState(false);
  const [showCustomForm, setShowCustomForm] = useState(false);
  const [showLoginPopup, setShowLoginPopup] = useState(false);
  const [isGenerating, setIsGenerating] = useState(false);
  const [showGeneratedVideo, setShowGeneratedVideo] = useState(false);
  const [generatedVideoUrl, setGeneratedVideoUrl] = useState(null);
  const [generationError, setGenerationError] = useState(null);
  const [activeTab, setActiveTab] = useState("/templates");
  const { user } = useContext(AuthContext);
  const userEmail = user?.email;
  const userUID = user?.uid;
  const navigate = useNavigate();
  const handleNavigation = (path) => {
    setActiveTab(path);
    navigate(path);
  };

  const handlePreview = (template) => {
    setSelectedTemplate(template);
    setShowPreview(true);
  };

  const handleGenerate = async (template) => {
    if (!user) {
      setShowLoginPopup(true);
      return;
    }

    setIsGenerating(true);
    setGenerationError(null);

    try {
      const response = await axios.post(`${serverbaseURL}create_template_video`, {
        themeTemplate: template.themeTemplate,
        topic: template.name,
        email: userEmail,
        uid: userUID
      });

      if (response.data.videoUrl) {
        setGeneratedVideoUrl(response.data.videoUrl);
        setShowGeneratedVideo(true);
      } else {
        throw new Error('No video URL received');
      }
    } catch (error) {
      console.error('Video generation failed:', error);
      setGenerationError(
        error.response?.data?.error ||
        'Failed to generate video. Please try again.'
      );
    } finally {
      setIsGenerating(false);
    }
  };

  const handleCustomFormSubmit = (e) => {
    e.preventDefault();
    setShowCustomForm(false);
  };

  const handleRedirectToLogin = () => {
    setShowLoginPopup(false);
    navigate("/Login", { state: { from: { pathname: "/Templates" } } });
  };

  return (
    <div className="p-8">


      <div className="flex justify-center items-center pt-5 ">
        <div className="flex space-x-8 items-center justify-center ">
          {/* Templates Button */}
          <motion.button
            onClick={() => handleNavigation("/templates")}
            className={`px-8 py-3 rounded-full font-semibold text-lg shadow-md transition-all duration-300 ${activeTab === "/templates"
              ? "bg-gradient-to-r from-[#805af5] to-blue-600 text-white shadow-lg"
              : "bg-gray-200 text-gray-600 hover:bg-gradient-to-r hover:from-[#6eaffb] hover:to-[#4d8bfd] hover:text-white hover:shadow-lg"
              }`}
            whileHover={{ scale: 1.1 }}
            whileTap={{ scale: 0.95 }}
          >
            Templates
          </motion.button>

          {/* My Generations Button */}
          <motion.button
            onClick={() => handleNavigation("/my-generations")}
            className={`px-8 py-3 rounded-full font-semibold text-lg shadow-md transition-all duration-300 ${activeTab === "/my-generations"
              ? "bg-gradient-to-r from-[#805af5] to-blue-600 text-white shadow-lg"
              : "bg-[#efefff] text-gray-600 hover:bg-gradient-to-r from-[#805af5] to-blue-600  hover:text-white hover:shadow-lg"
              }`}
            whileHover={{ scale: 1.1 }}
            whileTap={{ scale: 0.95 }}
          >
            My Generations
          </motion.button>
        </div>

      </div>


      {/* Generation Progress Modal */}
      {isGenerating && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center transition-opacity duration-300 z-[100]">
          <div className="bg-white rounded-lg p-6 shadow-xl w-80 text-center">
            <Loader2 className="animate-spin h-8 w-8 mx-auto mb-4 text-primary" />
            <h2 className="text-xl font-semibold mb-2">Generating Your Video</h2>
            <p className="text-gray-600">This may take a few minutes...</p>
          </div>
        </div>
      )}

      {/* Generated Video Modal */}
      {showGeneratedVideo && generatedVideoUrl && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center transition-opacity duration-300 z-[100]">
          <div className="bg-white rounded-lg p-4 shadow-xl w-80">
            <h2 className="text-xl font-bold text-gray-800 mb-3">Generated Video</h2>
            <div className="relative w-full aspect-[9/16]">
              <video
                controls
                className="w-full h-full rounded-md shadow"
                autoPlay
              >
                <source src={generatedVideoUrl} type="video/mp4" />
                Your browser does not support the video tag.
              </video>
            </div>
            <button
              onClick={() => {
                setShowGeneratedVideo(false);
                setGeneratedVideoUrl(null);
              }}
              className="w-full mt-3 bg-primary text-white py-2 rounded-md font-semibold hover:bg-opacity-90 transition duration-200"
            >
              Close
            </button>
          </div>
        </div>
      )}

      {/* Error Modal */}
      {generationError && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center transition-opacity duration-300 z-[100]">
          <div className="bg-white rounded-lg p-4 shadow-xl w-80">
            <h2 className="text-xl font-bold text-red-600 mb-3">Generation Failed</h2>
            <p className="text-gray-800 mb-3">{generationError}</p>
            <button
              onClick={() => setGenerationError(null)}
              className="w-full bg-gray-300 text-gray-800 py-2 rounded-md font-semibold hover:bg-gray-400 transition duration-200"
            >
              Close
            </button>
          </div>
        </div>
      )}

      <h1 className="text-4xl font-bold text-center  m-10 font-custom text-black">Explore Our Templates</h1>

      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8 mx-auto max-w-6xl">
        {templates.map((template) => (
          <div
            key={template.id}
            className="bg-gradient-to-br from-[#f8f8ff] via-[#e6e6fa] to-[#dcdcdc] rounded-xl overflow-hidden shadow-lg transform transition duration-300 hover:scale-105 flex flex-col"
          >
            <img src={template.image} alt={template.name} className="w-full h-56 object-cover rounded-t" />

            <div className="p-6 flex-grow flex flex-col justify-between">
              <div className="flex-grow">
                <h2 className="text-xl font-custom font-semibold mb-2">{template.name}</h2>
                <p className="mb-4 text-sm text-[#55595c]">{template.description}</p>
              </div>
              <p className="text-md text-slate-900 font-semibold">Type: {template.type}</p>
            </div>

            <div className="flex justify-between p-4">
              < motion.button
                onClick={() => handlePreview(template)}

                className="bg-gradient-to-r from-[#805af5] to-[#cd99ff] text-white font-semibold w-full px-3 py-2 rounded-lg shadow-lg hover:bg-opacity-90 transition duration-200 mr-2  hover:bg-gradient-to-r hover:from-[#6a3ec2] hover:to-[#a68cfc]     text-center "
              >
                Preview
              </motion.button>
              <motion.button
                onClick={() => handleGenerate(template)}

                className="bg-gradient-to-r from-[#805af5] to-[#cd99ff] text-white font-semibold w-full px-3 py-2 rounded-lg shadow-lg hover:bg-opacity-90 transition duration-200 mr-2  hover:bg-gradient-to-r hover:from-[#6a3ec2] hover:to-[#a68cfc]  ml-2  text-center "
              >
                Generate
              </motion.button>

            </div>
          </div>
        ))}
      </div>

      {/* Preview Modal */}
      {showPreview && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center transition-opacity duration-300 z-[100]">
          <div className="bg-white rounded-lg p-4 shadow-xl w-80">
            <h2 className="text-xl font-bold text-gray-800 mb-3">{selectedTemplate.name} Preview</h2>
            <div className="relative w-full aspect-[9/16]">
              <video
                controls
                className="w-full h-full rounded-md shadow"
                autoPlay
              >
                <source src={selectedTemplate.previewVideo} type="video/mp4" />
                Your browser does not support the video tag.
              </video>
            </div>
            <button
              onClick={() => setShowPreview(false)}
              className="w-full mt-3 bg-gradient-to-r from-[#805af5] to-[#cd99ff] text-white py-2 rounded-md font-semibold hover:bg-opacity-90 transition duration-200"
            >
              Close
            </button>
          </div>
        </div>
      )}

      {/* Custom Form Modal */}
      {showCustomForm && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center transition-opacity duration-300 z-[100]">
          <div className="bg-white rounded-lg p-4 shadow-xl w-80">
            <h2 className="text-xl font-bold text-gray-800 mb-3">Request a Custom Template</h2>

            <form onSubmit={handleCustomFormSubmit} className="space-y-3">
              <input
                type="text"
                placeholder="Your Name"
                className="w-full p-2 rounded border border-gray-300 focus:outline-none focus:ring-2 focus:ring-primary"
                required
              />
              <input
                type="email"
                placeholder="Your Email"
                className="w-full p-2 rounded border border-gray-300 focus:outline-none focus:ring-2 focus:ring-primary"
                required
              />
              <textarea
                placeholder="Describe your custom template requirements"
                className="w-full p-2 rounded border border-gray-300 focus:outline-none focus:ring-2 focus:ring-primary"
                rows="4"
                required
              ></textarea>

              <button
                type="submit"
                className="w-full bg-primary text-white font-semibold py-2 rounded hover:bg-opacity-90 transition duration-200"
              >
                Submit Request
              </button>
            </form>

            <button
              onClick={() => setShowCustomForm(false)}
              className="w-full mt-3 bg-gray-300 text-gray-800 py-2 rounded hover:bg-gray-400 transition duration-200"
            >
              Cancel
            </button>
          </div>
        </div>
      )}

      {/* Login Popup */}
      {showLoginPopup && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center transition-opacity duration-300 z-[100]">
          <div className="bg-white rounded-lg p-4 shadow-xl w-80">
            <h2 className="text-xl font-bold text-gray-800 mb-3">Login Required</h2>
            <p
              className="mb-3 text-blue-600 cursor-pointer hover:underline"
              onClick={handleRedirectToLogin}
            >
              Please log in to access this feature.
            </p>
            <button
              onClick={() => setShowLoginPopup(false)}
              className="w-full bg-gray-300 text-gray-800 py-2 rounded hover:bg-gray-400 transition duration-200"
            >
              Cancel
            </button>
          </div>
        </div>
      )}
    </div>
  );
}

export default Templates;