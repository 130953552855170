import { useContext, useEffect, useRef, useState } from "react";
import { GiHamburgerMenu } from "react-icons/gi";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { AuthContext } from "../provider/AuthProvider";
import logo from "../assets/logo.png";
import { motion } from "framer-motion";


const Navbar = () => {
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const { logOut, user } = useContext(AuthContext);
  const [isScrolled, setIsScrolled] = useState(false);
  const location = useLocation(); // To track route changes


  const dropdownRef = useRef(null);
  const navigate = useNavigate();
  // Scroll to top when the route changes
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);


  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 40) {
        setIsScrolled(true);
      } else {
        setIsScrolled(false);
      }
    };
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const toggleDropdown = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };

  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setIsDropdownOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);
  return (
    <div
      className={`w-full px-4 sm:px-10 lg:px-14 ${isScrolled ? 'fixed top-0 left-0 z-50 shadow-lg transition-all duration-300 ease-in-out bg-white border-b-2 border-gray-300' : ''
        }`}
    >
      <div
        className={`flex flex-row-reverse justify-between items-center py-2 transition-all duration-300 ease-in-out ${isScrolled ? 'bg-white' : 'bg-light-blue-100'
          }`}
      >
        <Link to="/">
          <div className="flex justify-center items-center gap-2 sm:gap-3 text-xl font-bold text-gray-800">
            <div className="text-4xl sm:text-5xl rounded-full p-2">
              <img src={logo} alt="Logo" className="w-8 sm:w-10 h-auto" />
            </div>
            <h1 className="text-gray-800 text-xl sm:text-2xl font-semibold">AutoMovieCreator</h1>
          </div>
        </Link>

        {/* Desktop Menu */}
        <div className="hidden lg:flex space-x-2 sm:space-x-4 px-4 sm:px-7 py-2">
          <motion.div
            className="group relative inline-block"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ delay: 0.2 }}
          >
            <Link
              to="/pricing"
              className="text-[#55595c] text-lg p-2 font-medium rounded-full border-2 border-transparent transition-all duration-300 group-hover:bg-gray-200"
            >
              Pricing
            </Link>
          </motion.div>

          <motion.div
            className="group relative inline-block"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ delay: 0.4 }}
          >
            <Link
              to="/support"
              className="text-[#55595c] text-lg font-medium p-2 rounded-full border-2 border-transparent transition-all duration-300 group-hover:bg-gray-200"
            >
              Support
            </Link>
          </motion.div>
          {user ? (
            <>
              <motion.div
                className="group relative inline-block"
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                transition={{ delay: 0.6 }}
              >
                <Link
                  to="/dashboard"
                  className="text-[#55595c] text-lg font-medium p-2 rounded-full border-2 border-transparent transition-all duration-300 group-hover:bg-gray-200"
                >
                  Dashboard
                </Link>
              </motion.div>

              <motion.div
                className="group relative inline-block"
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                transition={{ delay: 0.6 }}
              >
                <Link
                  to="/templates"
                  className="text-[#55595c] text-lg font-medium p-2 rounded-full border-2 border-transparent transition-all duration-300 group-hover:bg-gray-200"
                >
                  Template
                </Link>
              </motion.div>

              <motion.div
                className="group relative inline-block"
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                transition={{ delay: 0.8 }}
              >
                <Link
                  to="/ChannelAssets"
                  className="text-[#55595c] text-lg font-medium p-2 rounded-full border-2 border-transparent transition-all duration-300 group-hover:bg-gray-200"
                >
                  Channel Assets
                </Link>
              </motion.div>

              {/* <motion.div
                className="group relative inline-block"
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                transition={{ delay: 1 }}
              >
                <Link
                  to="/marketplace"
                  className="text-[#55595c] font-medium p-2 rounded-full border-2 border-transparent transition-all duration-300 group-hover:bg-gray-200"
                >
                  Marketplace
                </Link>
              </motion.div> */}

              <motion.div
                className="group relative inline-block"
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                transition={{ delay: 1.2 }}
              >
                <Link
                  to="#"
                  className="font-medium p-2 text-lg rounded-full border-2 text-red-700 border-transparent transition-all duration-300 group-hover:bg-gray-200"
                  onClick={logOut}
                >
                  Logout
                </Link>
              </motion.div>
            </>
          ) : (
            <motion.div
              className="group relative inline-block"
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              transition={{ delay: 1 }}
            >
              <Link
                to="/login"
                className="text-blue-700 text-lg font-medium p-2 rounded-full border-2 border-transparent transition-all duration-300 group-hover:bg-gray-200"
              >
                Login
              </Link>
            </motion.div>
          )}
        </div>

        {/* Mobile Menu */}
        <div className="lg:hidden relative" ref={dropdownRef}>
          <div
            className="text-xl text-accent border border-accent px-4 py-2 rounded-md"
            onClick={toggleDropdown}
          >
            <GiHamburgerMenu />
          </div>
          {isDropdownOpen && (
            <div className="absolute top-12 right-0 w-full max-w-xs sm:max-w-sm bg-white rounded-lg shadow-lg border border-gray-300 transition-all duration-300 ease-in-out">
              <Link to="/pricing">
                <button className="text-[#55595c] w-full text-left px-4 py-3 text-sm md:text-base transition-all duration-300 hover:bg-gray-100 hover:text-black rounded-t-lg">
                  Pricing
                </button>
              </Link>
              <Link to="/support">
                <button className="text-[#55595c] w-full text-left px-4 py-3 text-sm md:text-base transition-all duration-300 hover:bg-gray-100 hover:text-black">
                  Support
                </button>
              </Link>

              {user ? (
                <>
                  <Link to="/dashboard">
                    <button className="text-[#55595c] w-full text-left px-4 py-3 text-sm md:text-base transition-all duration-300 hover:bg-gray-100 hover:text-black">
                      Dashboard
                    </button>
                  </Link>
                  <Link to="/ChannelAssets">
                    <button className="text-[#55595c] w-full text-left px-4 py-3 text-sm md:text-base transition-all duration-300 hover:bg-gray-100 hover:text-black">
                      Channel Assets
                    </button>
                  </Link>
                  <button
                    className="text-red-600 w-full text-left px-4 py-3 text-sm md:text-base transition-all duration-300 hover:bg-red-100 hover:text-red-800 rounded-b-lg"
                    onClick={() => {
                      logOut();
                      setIsDropdownOpen(false);
                    }}
                  >
                    Logout
                  </button>
                </>
              ) : (
                <Link to="/login">
                  <button className="text-blue-700 w-full text-left px-4 py-3 text-sm md:text-base transition-all duration-300 hover:bg-gray-100 hover:text-blue-800 rounded-b-lg">
                    Login
                  </button>
                </Link>
              )}
            </div>
          )}
        </div>

      </div>
    </div>

  );
};

export default Navbar;