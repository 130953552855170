import React, { useState, useEffect, useContext } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { AuthContext } from '../provider/AuthProvider';
import axios from 'axios';
import { serverbaseURL } from '../constant/index.jsx';
import { Calendar, Clock, MessageSquare, Plus, X, Loader2, Send } from 'lucide-react';

function formatDateToISO(date, time) {
  const padToTwoDigits = (num) => (num < 10 ? '0' + num : num);
  const year = date.getFullYear();
  const month = padToTwoDigits(date.getMonth() + 1);
  const day = padToTwoDigits(date.getDate());
  const [hours, minutes] = time.split(':').map(Number);
  const istDate = new Date(Date.UTC(year, date.getMonth(), date.getDate(), hours, minutes, 0));
  istDate.setHours(istDate.getHours() - 5);
  istDate.setMinutes(istDate.getMinutes() - 30);
  const gmtHours = padToTwoDigits(istDate.getUTCHours());
  const gmtMinutes = padToTwoDigits(istDate.getUTCMinutes());
  return `${year}-${month}-${day} ${gmtHours}:${gmtMinutes}:00`;
}

const SchedulePost = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { user } = useContext(AuthContext);
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [selectedTime, setSelectedTime] = useState('');
  const [connectedAccounts, setConnectedAccounts] = useState([]);
  const [showSidebar, setShowSidebar] = useState(false);
  const [caption, setCaption] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const fetchConnectedAccounts = async () => {
      try {
        const response = await axios.post(`${serverbaseURL}fetch-accounts`, {
          uid: user.uid,
          email: user.email,
        });
        setConnectedAccounts(response.data.accounts);
      } catch (error) {
        console.error('Error fetching connected accounts:', error);
      }
    };
    fetchConnectedAccounts();
  }, [user]);

  const handleDateChange = (event) => {
    setSelectedDate(new Date(event.target.value));
  };

  const handleTimeChange = (event) => {
    setSelectedTime(event.target.value);
  };

  const handleSocialConnect = async (provider) => {
    setShowSidebar(false);
    try {
        const user = JSON.parse(localStorage.getItem("user"));
        if (!user || !user.uid || !user.email) {
            console.error("User information is missing from localStorage");
            return;
        }
        const { uid, email } = user;
        const response = await axios.post(`${serverbaseURL}connect-social-account`, {
          provider,
          uid,
          email,
        });
        const { connect_url } = response.data;
        if (connect_url) {
            window.open(connect_url, '_blank');
        }
      } catch (error) {
        console.error("Error connecting social account:", error);
      }
    };
  

  const handleSubmit = async () => {
    try {
      setIsLoading(true);
      const publishAt = formatDateToISO(selectedDate, selectedTime);
      const accountIds = connectedAccounts.filter((a) => a.selected).map((a) => a.id);
      const content = caption;
      const videoTitle = location.state.topic;

      const scheduledPostResponse = await axios.post(`${serverbaseURL}schedule-video-post`, {
        videoUrl: location.state.videoUrl,
        accountIds,
        publishAt,
        content,
        videoTitle,
      });

      if (scheduledPostResponse.status === 200) {
        alert('Post scheduled successfully!');
        navigate('/my-generations');
      }
    } catch (error) {
      alert('Failed to schedule the post. Please try again.');
      console.error('Error scheduling post:', error);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="min-h-screen bg-gray-50 py-10 px-4">
      <div className="max-w-4xl mx-auto">
        <h2 className="text-3xl font-bold text-gray-900 mb-8">Schedule Post</h2>
        <div className="bg-white shadow-lg rounded-xl p-8">
          <div className="mb-8">
            <label className="flex items-center gap-2 text-gray-700 font-medium mb-2">
              <Calendar className="w-5 h-5 text-gray-500" />
              Select Date
            </label>
            <input
              type="date"
              value={selectedDate.toISOString().slice(0, 10)}
              onChange={handleDateChange}
              className="w-full px-4 py-3 border border-gray-200 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent transition-all duration-200"
            />
          </div>

          <div className="mb-8">
            <label className="flex items-center gap-2 text-gray-700 font-medium mb-2">
              <Clock className="w-5 h-5 text-gray-500" />
              Select Time
            </label>
            <input
              type="time"
              value={selectedTime}
              onChange={handleTimeChange}
              className="w-full px-4 py-3 border border-gray-200 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent transition-all duration-200"
            />
          </div>

          <div className="mb-8">
            <label className="flex items-center gap-2 text-gray-700 font-medium mb-2">
              <MessageSquare className="w-5 h-5 text-gray-500" />
              Caption
            </label>
            <input
              type="text"
              value={caption}
              onChange={(e) => setCaption(e.target.value)}
              placeholder="Enter your caption here"
              className="w-full px-4 py-3 border border-gray-200 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent transition-all duration-200"
              required
            />
          </div>

          <div className="mb-8">
            <h3 className="text-xl font-semibold text-gray-900 mb-4">Connected Accounts</h3>
            <div className="space-y-3">
              {connectedAccounts.map((account) => (
                <div
                  key={account.id}
                  className="flex items-center justify-between bg-gray-50 rounded-lg px-6 py-4 hover:bg-gray-100 transition-colors duration-200"
                >
                  <div>
                    <h4 className="text-gray-800 font-medium">{account.name}</h4>
                    <p className="text-gray-500 text-sm">{account.type}</p>
                  </div>
                  <div className="flex items-center">
                    <input
                      type="checkbox"
                      checked={account.selected}
                      onChange={() => {
                        setConnectedAccounts(
                          connectedAccounts.map((a) =>
                            a.id === account.id ? { ...a, selected: !a.selected } : a
                          )
                        );
                      }}
                      className="h-5 w-5 text-blue-600 focus:ring-blue-500 border-gray-300 rounded cursor-pointer"
                    />
                  </div>
                </div>
              ))}
            </div>
          </div>

          <div className="flex justify-end gap-4">
            <button
              onClick={() => setShowSidebar(true)}
              className="flex items-center gap-2 px-6 py-3 bg-white border border-gray-200 text-gray-700 rounded-lg hover:bg-gray-50 transition-colors duration-200"
            >
              <Plus className="w-5 h-5" />
              Add Social
            </button>
            <button
              onClick={handleSubmit}
              disabled={!caption || isLoading}
              className="flex items-center gap-2 px-6 py-3 bg-blue-600 text-white rounded-lg hover:bg-blue-700 transition-colors duration-200 disabled:opacity-50 disabled:cursor-not-allowed"
            >
              {isLoading ? (
                <Loader2 className="w-5 h-5 animate-spin" />
              ) : (
                <Send className="w-5 h-5" />
              )}
              {isLoading ? 'Scheduling...' : 'Schedule Post'}
            </button>
          </div>
        </div>
      </div>

      {showSidebar && (
        <div className="fixed inset-0 bg-black bg-opacity-50 z-40">
          <div className="fixed top-0 right-0 w-96 h-full bg-white shadow-2xl z-50 p-8 overflow-y-auto">
            <button
              onClick={() => setShowSidebar(false)}
              className="absolute top-4 right-4 p-2 text-gray-500 hover:text-gray-700 rounded-full hover:bg-gray-100 transition-colors duration-200"
            >
              <X className="w-6 h-6" />
            </button>
            <h2 className="text-2xl font-bold mb-6">Connect Social Account</h2>
            <div className="space-y-3">
              {[
                'facebook',
                'twitter',
                'instagram',
                'linkedin',
                'google',
                'mastodon',
                'tiktok',
                'reddit',
                'youtube',
                'gmb',
                'pinterest',
                'threads',
                'bluesky',
              ].map((provider) => (
                <button
                  key={provider}
                  onClick={() => handleSocialConnect(provider)}
                  className="w-full text-left px-6 py-4 bg-gray-50 rounded-lg hover:bg-gray-100 transition-colors duration-200 capitalize"
                >
                  {provider}
                </button>
              ))}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default SchedulePost;