import React, { useState, useContext } from 'react';
import axios from 'axios';
import { AuthContext } from "../../provider/AuthProvider";
import { useNavigate } from 'react-router-dom';
import { serverbaseURL } from "../../constant/index";

export default function AddTemplate({ onAddTemplate }) {
  const { user } = useContext(AuthContext);
  const [formData, setFormData] = useState({
    title: '',
    description: '',
    category: '',
    price: '',
    creatorName: '',
    videoPreview: '',
    brandLogoLink: ''
  });
  const [file, setFile] = useState(null);
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);
  const [showTooltip, setShowTooltip] = useState(false); // New state for tooltip visibility
  const navigate = useNavigate();

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleFileChange = (e) => {
    setFile(e.target.files[0]);
  };

  const handlePriceFocus = () => {
    setShowTooltip(true);
  };

  const handlePriceBlur = () => {
    setShowTooltip(false);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setError('');

    const data = new FormData();
    data.append('file', file);
    data.append('title', formData.title);
    data.append('description', formData.description);
    data.append('category', formData.category);
    data.append('price', formData.price);
    data.append('creatorName', formData.creatorName);
    data.append('videoPreview', formData.videoPreview);
    data.append('brandLogoLink', formData.brandLogoLink);

    if (user) {
      data.append('userId', user.uid);
      data.append('email', user.email);
    } else {
      setError('User not authenticated');
      setLoading(false);
      return;
    }

    try {
      const response = await axios.post(`${serverbaseURL}add-template`, data, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });

      setFormData({
        title: '',
        description: '',
        category: '',
        price: '',
        creatorName: '',
        videoPreview: '',
        brandLogoLink: '',
      });
      setFile(null);
      // Show success alert and navigate after a delay
      alert("Template added successfully!");
      navigate("/marketplace");

    } catch (error) {
      console.error('Error adding template:', error);
      setError(error.response?.data?.message || 'Error adding template');
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="bg-white rounded-lg shadow-lg p-10 my-8 max-w-3xl mx-auto">
      <h3 className="text-3xl font-semibold text-gray-800 mb-6 text-center">Add New Template</h3>
      {error && <p className="text-red-500 text-center mb-4">{error}</p>}
      <form onSubmit={handleSubmit} className="space-y-6">
        <InputField label="Template Title" name="title" value={formData.title} onChange={handleChange} placeholder="Enter template title" />
        <InputField label="Template Description" name="description" value={formData.description} onChange={handleChange} placeholder="Enter template description" isTextArea />
        <InputField label="Category" name="category" value={formData.category} onChange={handleChange} placeholder="Enter category" />

        {/* Price input with tooltip */}
        <div className="relative">
          <InputField
            label="Price"
            name="price"
            value={formData.price}
            onChange={handleChange}
            onFocus={handlePriceFocus}
            onBlur={handlePriceBlur}
            placeholder="Enter price"
            type="number"
          />
          {showTooltip && (
            <PriceTooltip price={formData.price} />
          )}
        </div>

        <InputField label="Creator Name" name="creatorName" value={formData.creatorName} onChange={handleChange} placeholder="Enter your name" />
        <InputField label="Video Preview" name="videoPreview" value={formData.videoPreview} onChange={handleChange} placeholder="Enter video preview link" type="url" />
        <InputField label="Brand Logo Link" name="brandLogoLink" value={formData.brandLogoLink} onChange={handleChange} placeholder="Enter brand logo link" type="url" />
        <div>
          <label className="block text-sm font-medium text-gray-700">File Upload</label>
          <input type="file" onChange={handleFileChange} className="mt-2 p-2 block w-full border border-gray-300 rounded-md shadow-sm focus:border-indigo-500 focus:ring-indigo-500" required />
        </div>
        <button
          type="submit"
          className={`w-full p-3 mt-4 text-white font-semibold bg-indigo-600 rounded-md hover:bg-indigo-700 transition duration-200 ${loading ? 'opacity-50 cursor-not-allowed' : ''}`}
          disabled={loading}
        >
          {loading ? 'Adding...' : 'Add Template'}
        </button>
      </form>
    </div>
  );
}

// Tooltip component for price breakdown
function PriceTooltip({ price }) {
  const baseAmount = price * 0.8;
  const commission = price * 0.2;

  return (
    <div className="absolute top-full mt-1 bg-gray-100 border border-gray-300 rounded-md shadow-lg p-2 text-gray-700 text-sm w-full">
      <p><strong>Base Amount:</strong> ₹{baseAmount.toFixed(2)} (80%)</p>
      <p><strong>Commission:</strong> ₹{commission.toFixed(2)} (20%)</p>
      <p>Total: ₹{price}</p>
    </div>
  );
}

function InputField({ label, name, value, onChange, placeholder, type = 'text', isTextArea = false, ...rest }) {
  return (
    <div>
      <label className="block text-sm font-medium text-gray-700">{label}</label>
      {isTextArea ? (
        <textarea
          name={name}
          value={value}
          onChange={onChange}
          className="mt-2 p-4 block w-full border border-gray-300 rounded-md shadow-sm focus:border-indigo-500 focus:ring-indigo-500"
          placeholder={placeholder}
          {...rest}
        />
      ) : (
        <input
          type={type}
          name={name}
          value={value}
          onChange={onChange}
          className="mt-2 p-3 block w-full border border-gray-300 rounded-md shadow-sm focus:border-indigo-500 focus:ring-indigo-500"
          placeholder={placeholder}
          {...rest}
        />
      )}
    </div>
  );
}
