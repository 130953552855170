import React, { useState, useEffect } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import {
  Send,
  PlayCircle,
  Globe,
  Mic,
  Clock,
  ChevronRight,
  ChevronLeft,
  Check,
  ArrowLeft,
  Plus,
} from "lucide-react";

import {
  contentOptions,
  ChannelPrompts,
  narrationOptions,
  serverbaseURL,
} from "../constant/index.jsx";
import axios from "axios";

const EditVideo = () => {
  const imageGenToolOptions = ["DALL-E", "Midjourney", "Stable Diffusion"];
  const voiceModelOptions = ["Default", "Neural TTS", "Custom Voice"];
  const socialMediaProviders = [
    "facebook", "twitter", "instagram", "linkedin", "google",
    "mastodon", "tiktok", "reddit", "youtube", "gmb",
    "pinterest", "threads", "bluesky"
  ];
  const location = useLocation();
  const navigate = useNavigate();
  const [videoItem, setVideoItem] = useState(null);
  const [showConfirmation, setShowConfirmation] = useState(null);
  const [showPopup, setShowPopup] = useState(null);
  const [advancedData, setAdvancedData] = useState(null);
  const [activeTab, setActiveTab] = useState("videoDetails");
  const [userPlan, setUserPlan] = useState("free");
  const [series, setSeries] = useState({});
  const [connectedAccounts, setConnectedAccounts] = useState([]);
  const [selectedAccounts, setSelectedAccounts] = useState([]);
  const [showSidebar, setShowSidebar] = useState(false);

  console.log('user plan', userPlan)
  const handleTabClick = (tab) => {
    setActiveTab(tab);
  };
  const getNarratorNameById = (id) => {
    const narrator = narrationOptions.find((option) => option.id === id);
    return narrator ? narrator.name : "Unknown Narrator";
  };

  useEffect(() => {
    if (location.state && location.state.item) {
      setVideoItem(location.state.item);
      setAdvancedData({
        ...location.state.item,
      });
      fetchUserPlan(location.state.item.userEmail);
      fetchConnectedAccounts();
    } else {
      navigate("/");
    }
  }, [location, navigate]);

  const fetchUserPlan = async (email) => {
    try {
      const response = await axios.get(`${serverbaseURL}get_user_plan/${email}`);
      console.log("fetched user plan", response.data);
      setUserPlan(response.data.plan);
    } catch (error) {
      console.error("Error fetching user plan:", error);
    }
  };
  const fetchConnectedAccounts = async () => {
    try {
      const user = JSON.parse(localStorage.getItem("user"));
      if (!user || !user.uid || !user.email) {
        console.error("User information is missing from localStorage");
        return;
      }

      const { uid, email } = user;

      console.log('UID:', uid);
      console.log('Email:', email);

      const response = await axios.post(`${serverbaseURL}fetch-accounts`, {
        uid,
        email,
      });
      setConnectedAccounts(response.data.accounts);
    } catch (error) {
      console.error("Error fetching connected accounts:", error);
    }
  };

  const handleAdvancedChange = (e) => {
    const { name, value, type, checked } = e.target;

    if (type === "checkbox") {
      let updatedDays = checked
        ? [...advancedData.scheduleDays, value]
        : advancedData.scheduleDays.filter((day) => day !== value);

      if (userPlan === "starter" && updatedDays.length > 3) {
        updatedDays = updatedDays.slice(0, 3);
      } else if (userPlan === "daily" && updatedDays.length > 7) {
        updatedDays = updatedDays.slice(0, 7);
      }

      setAdvancedData((prevData) => ({
        ...prevData,
        scheduleDays: updatedDays,
      }));
    } else if (name === "scheduleTime") {
      if (userPlan === "hardcore") {
        setAdvancedData((prevData) => {
          const newScheduleTime = [...(prevData.scheduleTime || [])];
          if (newScheduleTime.length < 2) {
            newScheduleTime.push(value);
          } else {
            newScheduleTime[1] = value;
          }
          return {
            ...prevData,
            scheduleTime: newScheduleTime,
          };
        });
      } else {
        setAdvancedData((prevData) => ({
          ...prevData,
          scheduleTime: value ? [value] : [],
        }));
      }
    } else {
      setAdvancedData((prevData) => ({
        ...prevData,
        [name]: value,
      }));
    }
  };
  const handleAccountSelection = (accountId) => {
    setSelectedAccounts((prev) =>
      prev.includes(accountId)
        ? prev.filter((id) => id !== accountId)
        : [...prev, accountId]
    );
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!advancedData.scheduleDays || advancedData.scheduleDays.length === 0) {
      alert("Please select at least one scheduled day.");
      return;
    }

    if (!advancedData.scheduleTime || advancedData.scheduleTime.length === 0) {
      alert("Please select a scheduled time.");
      return;
    }
    if (userPlan === "free") {
      alert("Scheduling is a premium feature. Please upgrade your plan.");
      navigate("/dashboard/billing");
      return;
    }
    try {
      const response = await axios.put(`${serverbaseURL}edit_series`, {
        advancedData: advancedData,
        selectedAccounts: selectedAccounts,
      });
      alert(`${response.data.message}`);
    } catch (error) {
      if (error.response && error.response.data && error.response.data.message) {
        alert(`Error: ${error.response.data.message}`);
      } else {
        alert("An error occurred while updating the series.");
      }
      console.error(error);
    }
  };

  const confirmStopChannel = async () => {
    try {
      const response = await axios.put(`${serverbaseURL}toggle_series_status/${advancedData._id}`);
      const { message, result } = response.data;
      if (message.includes("Series status updated to")) {
        const newStatus = message.includes("Stopped") ? "Stopped" : "Active";
        setAdvancedData((prevData) => ({
          ...prevData,
          status: newStatus,
        }));
      }
    } catch (error) {
      console.error("Error toggling channel status:", error);
    }
    setShowConfirmation(false);
    setShowPopup(true);
  };
  const handleSocialConnect = async (provider) => {
    try {
      const user = JSON.parse(localStorage.getItem("user"));
      if (!user || !user.uid || !user.email) {
        console.error("User information is missing from localStorage");
        return;
      }
      const { uid, email } = user;
      const response = await axios.post(`${serverbaseURL}connect-social-account`, {
        provider,
        uid,
        email,
      });
      const { connect_url } = response.data;
      if (connect_url) {
        window.open(connect_url, '_blank');
      }
    } catch (error) {
      console.error("Error connecting social account:", error);
    }
  };

  const toggleSidebar = () => {
    setShowSidebar(!showSidebar);
  };

  const popupClose = () => {
    setShowPopup(false);
  };

  const handleStopChannel = () => {
    setShowConfirmation(true);
  };

  const renderAdvancedForm = () => {
    if (!advancedData) return null;

    return (
      <div className="mt-4 p-8 bg-[#efefff] rounded-lg shadow-lg">
        <div className="mb-6 flex justify-between items-center">
          <h2 className="text-3xl font-semibold font-custom text-black ">
            Advanced Options
          </h2>
          <div
            className={`px-6 py-3 rounded-full font-semibold text-center text-sm md:text-base transition duration-200 ease-in-out ${advancedData.status === "Active"
              ? "bg-gradient-to-r from-green-400 to-green-600 text-white shadow-lg"
              : "bg-gradient-to-r from-red-400 to-red-600 text-white shadow-lg"
              }`}
          >
            {advancedData.status || "Unknown"}
          </div>

        </div>
        <div className="mb-6">
          <ul className="flex ">
            <li className="-mb-px mr-2">
              <button
                className={`${activeTab === "videoDetails"
                  ? "bg-gradient-to-r from-[#805af5] to-blue-600 text-white py-2 px-4 rounded-md text-md font-semibold shadow-lg"
                  : "bg-white text-blue-700 hover:text-blue-800 font-semibold py-2 px-4 rounded-md text-md"
                  } transition duration-200 hover:shadow-lg`}
                onClick={() => handleTabClick("videoDetails")}
              >
                Video Details
              </button>
            </li>
            <li className="ml-4">
              <button
                className={`${activeTab === "schedule"
                  ? "bg-gradient-to-r from-[#805af5] to-blue-600 text-white py-2 px-6 rounded-md text-md font-semibold shadow-lg"
                  : "bg-white text-blue-500 hover:text-blue-800 font-semibold py-2 px-6 rounded-md text-md"
                  } transition duration-200 hover:shadow-lg`}
                onClick={() => handleTabClick("schedule")}
              >
                Schedule
              </button>
            </li>
          </ul>
        </div>


        <div
          id="videoDetails"
          className={`tab-content ${activeTab === "videoDetails" ? "" : "hidden"
            }`}
        >
          <div className="space-y-6">
            <div className="form-group">
              <label className="block text-lg font-semibold text-[#55595c] mb-1">
                Video Topic
              </label>
              <select
                name="topic"
                value={advancedData.topic}
                onChange={handleAdvancedChange}
                className="w-full px-4 py-2 rounded-lg bg-[#f9f9f9] border border-[#d1d1d6] focus:border-[#4f75e1] focus:ring-[#4f75e1] focus:outline-none transition duration-200"
              >
                <option value="">Select Video Topic</option>
                {contentOptions.map((option, index) => (
                  <option key={option.id || index} value={option.name}>
                    {option.name}
                  </option>
                ))}
              </select>
            </div>
            <div className="form-group">
              <label className="block text-lg font-semibold text-[#55595c] mb-1">
                Narrator Voice
              </label>
              <select
                name="narrator"
                value={getNarratorNameById(advancedData.narrator)}
                onChange={handleAdvancedChange}
                className="w-full px-4 py-2 rounded-lg bg-[#f9f9f9] border border-[#d1d1d6] focus:border-[#4f75e1] focus:ring-[#4f75e1] focus:outline-none transition duration-200"
              >
                <option value="">Select Narrator Voice </option>
                {narrationOptions.map((option) => (
                  <option key={option.id} value={option.name}>
                    {option.name}
                  </option>
                ))}
              </select>
            </div>
          </div>
        </div>
        <div
          id="schedule"
          className={`tab-content ${activeTab === "schedule" ? "" : "hidden"}`}
        >
          {userPlan !== "free" && (
            <div className="space-y-6">
              <div className="form-group">
                <label className="block text-lg font-semibold text-[#55595c] mb-1">Select Days for Schedule</label>
                <div className="flex flex-wrap gap-3">
                  {["Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday", "Sunday"].map((day) => (
                    <label key={day} className="flex items-center  text-[#55595c] space-x-2">
                      <input
                        type="checkbox"
                        name="scheduleDays"
                        value={day}
                        checked={advancedData?.scheduleDays?.includes(day)}
                        onChange={handleAdvancedChange}
                        className="h-4 text-[#55595c w-4"
                        disabled={
                          (userPlan === "starter" && advancedData.scheduleDays.length >= 3 && !advancedData.scheduleDays.includes(day)) ||
                          (userPlan === "daily" && advancedData.scheduleDays.length >= 7 && !advancedData.scheduleDays.includes(day))
                        }
                      />
                      <span>{day}</span>
                    </label>
                  ))}
                </div>
              </div>
              <div className="form-group mb-2">
                <label className="block text-lg  font-semibold text-[#55595c] mb-1">Schedule Time</label>
                {userPlan === "hardcore" ? (
                  <>
                    {[...Array(2)].map((_, index) => (
                      <input
                        key={index}
                        type="time"
                        name="scheduleTime"
                        value={advancedData.scheduleTime?.[index] || ""}
                        onChange={(e) => handleAdvancedChange(e, index)}
                        className="w-full px-4 py-2 mb-2 rounded-lg bg-[#f9f9f9] border border-[#d1d1d6] focus:border-[#4f75e1] focus:ring-[#4f75e1] focus:outline-none transition duration-200"
                      />
                    ))}
                  </>
                ) : (
                  <input
                    type="time"
                    name="scheduleTime"
                    value={advancedData.scheduleTime?.[0] || ""}
                    onChange={handleAdvancedChange}
                    className="w-full px-4 py-2 mt-2 rounded-lg bg-[#f9f9f9] border border-[#d1d1d6] focus:border-[#4f75e1] focus:ring-[#4f75e1] focus:outline-none transition duration-200"
                  />
                )}
              </div>

              <div className="form-group pb-3 ">
                <div className="flex justify-between items-center mb-4">
                  <label className="block text-lg font-semibold text-slate-900">Connected Social Accounts</label>
                  <button
                    onClick={toggleSidebar}
                    className="flex items-center px-6 py-3 bg-gradient-to-r from-[#805af5] to-blue-600 text-white   rounded-md text-md font-semibold hover:shadow-lg transition duration-200"
                  >
                    <Plus className="mr-2" size={20} />
                    Add Socials
                  </button>
                </div>
                <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
                  {connectedAccounts.map((account) => (
                    <div
                      key={account.id}
                      className="flex items-center p-4 border-[1px] border-[#805af5] rounded-xl bg-[#f8f8ff]  h-full shadow-lg hover:shadow-2xl hover:border-[#0d6efd]  "
                    >
                      <input
                        type="checkbox"
                        checked={selectedAccounts.includes(account.id)}
                        onChange={() => handleAccountSelection(account.id)}
                        className="h-4 w-4 mr-3"
                      />
                      <div>
                        <p className="font-medium  text-sm  text-gray-800 capitalize">{account.type}</p>
                        <p className="text-sm  text-[#55595c]">{account.name}</p>
                      </div>
                    </div>
                  ))}
                  {connectedAccounts.length === 0 && (
                    <div className="col-span-full text-md text-center py-4 text-[#55595c] ">
                      No social accounts connected. Click "Add Socials" to connect.
                    </div>
                  )}
                </div>
              </div>
            </div>
          )}
          {userPlan === "free" && (
            <div className="text-center py-4">
              <p className="text-lg font-semibold text-gray-700">Scheduling is available in premium plans.</p>
              <button
                onClick={() => navigate("/dashboard/billing")}
                className="mt-4 px-6 py-2 bg-indigo-600 text-white rounded-full hover:bg-indigo-700 transition duration-300"
              >
                Upgrade Now
              </button>
            </div>
          )}
        </div>

        <div className="flex flex-col md:flex-row justify-between mt-8 space-y-4 md:space-y-0">
          <button
            onClick={handleSubmit}
            className="flex items-center justify-center w-full md:w-1/2 px-10 py-4 bg-gradient-to-r from-[#805af5] to-blue-600 text-white text-lg font-semibold rounded-full shadow-lg hover:shadow-xl hover:bg-gradient-to-r hover:from-[#6a48d1] hover:to-blue-500 transition duration-300 ease-in-out transform hover:scale-105 md:mr-2"
          >
            Save
            <Check className="ml-3" size={24} />
          </button>
          <button
            onClick={handleStopChannel}
            className={`flex items-center justify-center w-full md:w-1/2 px-10 py-4 ${advancedData.status === "Active"
              ? "bg-gradient-to-r from-red-500 to-red-700"
              : "bg-gradient-to-r from-green-500 to-green-700"
              } text-white text-lg font-semibold rounded-full shadow-lg hover:shadow-xl hover:bg-opacity-90 transition duration-300 ease-in-out transform hover:scale-105 md:ml-2`}
          >
            {advancedData.status === "Active" ? "Stop" : "Activate"} Channel
          </button>
        </div>


      </div>
    );
  };

  if (!videoItem || !advancedData) {
    return <div>Loading...</div>;
  }

  return (
    <div className="edit-video-container px-6  bg-white pb-6 pt-3 ">
      <div className=" ml-10">
        <Link
          to="/dashboard"
          className="flex items-center pt-7 text-indigo-600 hover:text-indigo-700 transition duration-300 ease-in-out"
        >
          <ArrowLeft className="mr-2" size={20} />
          <span className="text-lg font-bold">Back to Dashboard</span>
        </Link>
      </div>
      {showSidebar && (
        <div className="sidebar fixed top-0 right-0 w-full md:w-1/4 h-full bg-white shadow-lg z-50 p-6 md:p-8 transition-transform duration-300 ease-in-out transform md:translate-x-0 translate-x-full">
          <button
            onClick={toggleSidebar}
            className="absolute top-4 left-4 text-gray-600 hover:text-indigo-600 transition-all duration-200"
          >
            <ChevronRight size={24} />
          </button>
          <h2 className="text-md font-custom font-semibold text-gray-700 mb-4 text-center md:text-left">Connect Social Account</h2>
          <div className="space-y-4 overflow-y-auto max-h-[calc(100vh-100px)]">
            {socialMediaProviders.map((provider) => (
              <button
                key={provider}
                onClick={() => handleSocialConnect(provider)}
                className="block w-full text-left px-4 py-3 bg-gray-100 rounded-lg hover:text-violet-500 hover:bg-indigo-50 focus:ring-2 focus:ring-indigo-500 transition-colors duration-300"
              >
                {provider.charAt(0).toUpperCase() + provider.slice(1)}
              </button>
            ))}
          </div>
        </div>

      )}
      <div className=" p-8 bg-white ">
        {renderAdvancedForm()}
      </div>
      {showConfirmation && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center">
          <div className="bg-white p-8 rounded-lg shadow-xl">
            <h3 className="text-2xl font-bold mb-4">Confirm Action</h3>
            <p className="mb-6">
              Are you sure you want to {advancedData.status === "Active" ? "stop" : "activate"} this channel?
            </p>
            <div className="flex justify-end space-x-4">
              <button
                onClick={() => setShowConfirmation(false)}
                className="px-4 py-2 bg-gray-300 text-gray-800 rounded hover:bg-gray-400 transition duration-300"
              >
                Cancel
              </button>
              <button
                onClick={confirmStopChannel}
                className="px-4 py-2 bg-red-600 text-white rounded hover:bg-red-700 transition duration-300"
              >
                Confirm
              </button>
            </div>
          </div>
        </div>
      )}

      {showPopup && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center">
          <div className="bg-white p-8 rounded-lg shadow-xl">
            <h3 className="text-2xl font-bold mb-4">Information</h3>
            <p className="mb-6">
              Channel has been {advancedData.status === "Stopped" ? "stopped" : "activated"}.
            </p>
            <div className="flex justify-end space-x-4">
              <button
                onClick={popupClose}
                className="px-4 py-2 bg-gray-300 text-gray-800 rounded hover:bg-gray-400 transition duration-300"
              >
                Ok
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default EditVideo;